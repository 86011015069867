/*
@File: StartP React Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - Boxes CSS
** - About CSS
** - Why Choose Us CSS
** - Services CSS
** - Services Details CSS
** - Features CSS
** - Features Details CSS
** - Team CSS
** - Works CSS
** - Fun Facts CSS
** - Pricing CSS
** - Domain Search CSS
** - Feedback CSS
** - Ready To Talk CSS
** - Partner CSS
** - Blog CSS
** - Shop CSS
** - Shop Details CSS
** - Cart CSS
** - Checkout CSS
** - Page Title CSS
** - FAQ CSS
** - Pagination CSS
** - Blog Details CSS
** - Project Details CSS
** - Contact CSS
** - Coming Soon CSS
** - 404 Error CSS
** - CTA CSS
** - Footer CSS
** - Go Top CSS
#61b4fc
#51a7f3
*/

/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

$font-family: "Poppins", sans-serif;
$light-green-color: #428bca;
$white-color: #ffffff;
$black-color: #0e314c;
$paragraph-color: #6084a4;
$purple-color: #428bca;
$bg-color: #f7fafd;
$bg-color2: #f9f6f6;
$bg-color3: #f9fafb;
$bg-color4: #0f054b;
$hosting-main-color: #032b8d;
$gradient-color: linear-gradient(
  150deg,
  rgba(81, 167, 243, 0.9) 0%,
  rgba(66, 139, 202, 0.9) 100%
);
$gradient-color3: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
$gradient-color4: linear-gradient(
  150deg,
  rgba(81, 167, 243, 0.9) 0%,
  rgba(66, 139, 202, 0.9) 100%
);

/*
$gradient-color5: linear-gradient(
  150deg,
  rgba(173, 172, 172, 0.493) 0%,
  rgba(173, 172, 172, 0.9) 100%
);
*/

/*
$gradient-color5: linear-gradient(
  150deg,
  rgba(209, 153, 89, 0.493) 0%,
  rgba(209, 153, 89, 0.9) 100%
);

*/

/*
$gradient-color5: linear-gradient(
  150deg,
  rgba(171, 89, 209, 0.493) 0%,
  rgba(171, 89, 209, 0.9) 100%
);
*/

/*
$gradient-color5: linear-gradient(
  150deg,
  rgba(89, 209, 95, 0.493) 0%,
  rgba(69, 187, 75, 0.9) 100%
);
*/

$gradient-color5: linear-gradient(
  150deg,
  rgba(166, 182, 196, 0.9) 0%,
  rgba(123, 129, 134, 0.9) 100%
);

$gradient-color2: linear-gradient(
  to right bottom,
  #1fa2ff,
  #00c0ff,
  #00d9f2,
  #53eedd,
  #a6ffcb
);
$transition: 0.5s;
$font-size: 14px;

body {
  font-family: $font-family;
  padding: 0;
  margin: 0;
  font-size: $font-size;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  color: $black-color;
  display: inline-block;
  transition: $transition;
  text-decoration: none;
  outline: 0 !important;

  &:hover,
  &:focus {
    color: $light-green-color;
    text-decoration: none;
  }
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
p {
  color: $paragraph-color;
  line-height: 1.9;

  &:last-child {
    margin-bottom: 0;
  }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-color;
  font-weight: 500;
}
.ptb-80 {
  padding: {
    top: 80px;
    bottom: 80px;
  }
}
.bg-f7fafd {
  background-color: $bg-color;
}
.bg-f9f6f6 {
  background-color: $bg-color2;
}
.bg-f9fafb {
  background-color: $bg-color3;
}
.bg-0f054b {
  background-color: $bg-color4;
}
/*owl carousel btn*/
.owl-theme {
  .owl-nav {
    margin-top: 0;
  }
  .owl-dots {
    margin-top: 20px;
    line-height: 0.01;

    .owl-dot {
      span {
        width: 15px !important;
        height: 15px !important;
        margin: 0 5px !important;
        background: #d6d6d6 !important;
        transition: $transition;
        border-radius: 50%;
      }
      &:hover,
      &.active {
        span {
          background: $light-green-color !important;
        }
      }
    }
  }
}
/*btn btn-primary*/
.btn-primary {
  color: $white-color;
  background-color: $light-green-color;
  box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);

  &:hover,
  &:focus,
  &:active {
    color: $white-color;
    background: $black-color;
    transform: translateY(-5px);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  }
  &.focus,
  &:focus {
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  & .show > &.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  & .show > &.dropdown-toggle {
    color: $white-color;
    background: $black-color;
    transform: translateY(-5px);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  }
  &.disabled,
  &:disabled {
    color: $white-color;
    background-color: $light-green-color;
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    opacity: 1;
  }
  &.disabled:hover,
  &.disabled:focus,
  &:disabled:hover,
  &:disabled:focus {
    background: $black-color;
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  }
}
.btn-light {
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: unset;
  }
  &.focus,
  &:focus {
    box-shadow: unset;
  }
}
.btn {
  border: none;
  padding: 15px 40px;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  text-transform: uppercase;
  transition: $transition;
  font: {
    weight: 600;
    size: 14px;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0;
    transition: $transition;
    background: $purple-color;
    z-index: -1;
  }
  &::after {
    left: 0;
    border-radius: 4px 0 0 4px;
  }
  &::before {
    right: 0;
    border-radius: 0 4px 4px 0;
  }
  &:hover {
    &::after,
    &::before {
      width: 50%;
    }
  }
}
/*form control*/
.form-group {
  margin-bottom: 18px;
}
label {
  margin-bottom: 10px;
  color: $black-color;
  font: {
    size: 15px;
    weight: 500;
  }
}
.form-control {
  height: 45px;
  padding: 10px;
  font-size: 14px;
  color: $black-color;
  border: 1px solid #eeeeee;
  border-radius: 0;
  background: #eeeeee;
  transition: $transition;

  &:focus {
    outline: 0;
    background: $white-color;
    box-shadow: unset;
    border-color: $light-green-color;
  }
}
/*Section Title*/
.section-title {
  text-align: center;
  margin-bottom: 60px;

  h2 {
    margin-bottom: 0;
    text-transform: capitalize;
    font: {
      size: 24px;
      weight: 600;
    }
  }
  p {
    max-width: 520px;
    margin: 0 auto;
  }
  .bar {
    height: 5px;
    width: 90px;
    background: #cdf1d8;
    margin: 20px auto;
    position: relative;
    border-radius: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -2.7px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: $light-green-color;
      animation: {
        duration: 3s;
        timing-function: linear;
        iteration-count: infinite;
        name: MOVE-BG;
      }
    }
  }
}
@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(88px);
  }
}

/*================================================
Preloader CSS
=================================================*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: $light-green-color;

  .spinner {
    width: 60px;
    height: 60px;
    background-color: $white-color;
    margin: 0 auto;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    position: absolute;
    right: 0;
    left: 0;
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.4);
    top: 50%;
    margin-top: -75px;
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*================================================
Navbar CSS
=================================================*/
#header {
  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

    .startp-nav {
      background-color: $white-color !important;
      box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      padding: 5px 0;
    }
  }
}
.startp-nav {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 15px;
  width: 100%;
  z-index: 999;
  height: auto;

  .navbar {
    padding: 0;
  }
  &.p-relative {
    position: relative;
    padding-bottom: 15px;
  }
  nav {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
    .navbar-nav {
      .nav-item {
        position: relative;
        padding: 15px 0;

        a {
          font: {
            weight: 500;
            size: 15px;
          }
          color: #4a6f8a;
          padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          margin: {
            left: 14px;
            right: 14px;
          }
          &:hover,
          &:focus,
          &.active {
            color: $light-green-color;
          }
          svg {
            width: 14px;
          }
          span {
            display: inline-block;
            background-color: red;
            color: $white-color;
            font-size: 9px;
            width: 13px;
            height: 13px;
            text-align: center;
            line-height: 14px;
            border-radius: 50%;
            position: relative;
            top: -7px;
            right: 4px;
          }
        }
        .dropdown_menu {
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
          background: $white-color;
          position: absolute;
          top: 80px;
          left: 0;
          width: 250px;
          z-index: 99;
          opacity: 0;
          border-radius: 5px;
          visibility: hidden;
          transition: all 0.3s ease-in-out;
          padding: {
            top: 20px;
            left: 5px;
            right: 5px;
            bottom: 20px;
          }
          li {
            position: relative;
            padding: 0;

            a {
              padding: 9px 15px;
              margin: 0;
              display: block;
              color: #4a6f8a;
              font: {
                size: 14px;
                weight: 500;
              }
              &:hover,
              &:focus,
              &.active {
                color: $light-green-color;
              }
            }
            .dropdown_menu {
              position: absolute;
              left: 220px;
              top: 0;
              opacity: 0 !important;
              visibility: hidden !important;

              li {
                a {
                  color: #4a6f8a;

                  &:hover,
                  &:focus,
                  &.active {
                    color: $light-green-color;
                  }
                }
              }
            }
            &:hover {
              .dropdown_menu {
                opacity: 1 !important;
                visibility: visible !important;
                top: -20px !important;
              }
              a {
                color: $light-green-color;
              }
            }
          }
        }
        &:hover {
          ul {
            opacity: 1;
            visibility: visible;
            top: 100%;
          }
        }
      }
    }
    .others-option {
      margin-left: 20px;

      .btn {
        &.btn-light {
          background: transparent;
          border: 2px dashed #cdf1d8;
          padding: 12px 33px;
          margin-right: 10px;

          &:hover,
          &:focus {
            color: $white-color;
            border-color: $light-green-color;
          }
          &:focus {
            background-color: $light-green-color;
            color: $white-color;
            border-color: $light-green-color;
          }
          &::after,
          &::before {
            background: $light-green-color;
          }
        }
        &.btn-primary {
          background: $purple-color;
          box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

          &::after,
          &::before {
            background: $light-green-color;
            box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
          }
        }
      }
    }
  }
  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
    z-index: 999;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: $white-color !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
  position: relative;
  height: 910px;
  z-index: 1;
  // background: transparent url(../images/banner-bg1.jpg) right top no-repeat;

  .hero-content {
    h1 {
      margin-bottom: 20px;
      font: {
        size: 45px;
        weight: 500;
      }
    }
    p {
      margin-bottom: 30px;
    }
  }
  .banner-form {
    background: $white-color;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 30px;
    border-radius: 5px;

    form {
      .form-control {
        padding-left: 15px;
      }
      .btn {
        display: block;
        width: 100%;
        -webkit-transform: unset;
        transform: unset;
        margin-top: 25px;
      }
    }
  }
  .banner-image {
    position: relative;

    img {
      position: absolute;

      &:nth-child(1) {
        z-index: 3;
        top: -130px;
        left: -45px;
      }
      /*
            &:nth-child(2) {
                right: 145px;
                top: -287px;
            }
            &:nth-child(3) {
                left: 25px;
                top: -20px;
            }
            &:nth-child(4) {
                right: 12%;
                top: 118px;
                z-index: 1;
            }
            &:nth-child(5) {
                left: 28%;
                top: -120px;
                z-index: 2;
            }
            &:nth-child(6) {
                right: 35%;
                z-index: 1;
                top: -150px;
            }
            &:nth-child(7) {
                right: 20px;
                top: -110px;
            }
            &:nth-child(8) {
                right: 25px;
                top: -140px;
            }
            &:nth-child(9) {
                right: 30%;
                z-index: 1;
                top: -35px;
            }
            &:nth-child(10) {
                left: 37%;
                top: -28px;
                z-index: 1;
            }
            &:nth-child(11) {
                left: 37%;
                top: -160px;
                z-index: 1;
            }
            &:nth-child(12) {
                left: 90px;
                top: -110px;
            }
            &:nth-child(13) {
                left: 56%;
                top: -25px;
                z-index: 2;
            }
            &:nth-child(14) {
                right: 105px;
                top: -28px;
            }
            */
      &:last-child {
        display: none;
      }
    }
    &.single-banner-image {
      img {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
.hosting-main-banner {
  height: 650px;
  position: relative;
  z-index: 1;
  background-color: $hosting-main-color;

  .hosting-banner-content {
    h1 {
      color: $white-color;
      margin-bottom: 25px;
      font: {
        size: 45px;
        weight: 500;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 12px;
        color: $white-color;
        position: relative;
        padding-left: 30px;

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 20px;
          height: 2px;
          background: $light-green-color;
          content: "";
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    p {
      color: $white-color;
      font-size: 18px;
      margin: {
        bottom: 30px;
        top: 25px;
      }
      span {
        text-decoration: line-through;
      }
    }
  }
  .hosting-banner-image {
    position: relative;

    img {
      position: absolute;

      &:nth-child(1) {
        top: -175px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:nth-child(2) {
        top: 184px;
        left: 21%;
      }
      &:nth-child(3) {
        top: 177px;
        right: 38px;
      }
      &:nth-child(4) {
        left: 29%;
        right: 0;
        top: -15px;
        margin: 0 auto;
      }
      &:nth-child(5) {
        right: 8px;
        top: -107px;
      }
      &:nth-child(6) {
        left: 18%;
        top: -175px;
        z-index: -1;
      }
      &:nth-child(7) {
        top: -235px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:nth-child(8) {
        left: 27%;
        top: -200px;
      }
      &:nth-child(9) {
        z-index: -2;
        top: 40px;
        left: 80px;
        right: 0;
        margin: 0 auto;
      }
      &:nth-child(10) {
        display: none;
      }
    }
    &.single-hosting-banner-image {
      img {
        display: block;
        position: relative;
        top: 0 !important;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
.shape1 {
  position: absolute;
  top: 20%;
  z-index: -1;
  left: 30px;
  -webkit-animation: rotate3d 4s linear infinite;
  animation: rotate3d 4s linear infinite;
  opacity: 0.4;
}
.shape2 {
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 15%;
}
.shape3 {
  position: absolute;
  left: 25%;
  bottom: 15%;
  z-index: -1;
  -webkit-animation: animationFramesOne 15s infinite linear;
  animation: animationFramesOne 15s infinite linear;
}
.shape4 {
  position: absolute;
  right: 25%;
  bottom: 15%;
  z-index: -1;
  -webkit-animation: animationFramesOne 20s infinite linear;
  animation: animationFramesOne 20s infinite linear;
}
.shape5 {
  position: absolute;
  right: 5%;
  top: 10%;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  opacity: 0.2;
}
.shape6 {
  position: absolute;
  z-index: -1;
  top: 40%;
  right: 10%;
}
.shape7 {
  position: absolute;
  left: 25%;
  top: 15%;
  z-index: -1;
  -webkit-animation: animationFramesOne 20s infinite linear;
  animation: animationFramesOne 20s infinite linear;
}
.shape8 {
  position: absolute;
  z-index: -1;
  top: 15%;
  right: 10%;
}
@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

/*================================================
Boxes CSS
=================================================*/
.boxes-area {
  position: relative;
  padding-bottom: 80px;
  margin-top: -130px;
  z-index: 1;
}
.hosting-boxes-area {
  background-color: $hosting-main-color;
  margin-top: 0;

  .single-box {
    padding: {
      left: 15px;
      right: 15px;
    }
  }
}
.single-box {
  border: 1px dashed #cdf1d8;
  padding: 30px 20px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: $white-color;
  -webkit-box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  -webkit-transition: $transition;
  transition: $transition;

  .icon {
    display: inline-block;
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: $light-green-color;
    -webkit-transition: $transition;
    transition: $transition;
  }
  h3 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 11px;
    -webkit-transition: $transition;
    transition: $transition;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: $transition;
    transition: $transition;
  }
  p {
    -webkit-transition: $transition;
    transition: $transition;
  }
  &.bg-f78acb {
    border-color: rgba(247, 138, 203, 0.3);

    .icon {
      color: #f78acb;
      background: rgba(247, 138, 203, 0.3);
    }
  }
  &.bg-c679e3 {
    border-color: #edc3fc;

    .icon {
      color: $purple-color;
      background: #edc3fc;
    }
  }
  &.bg-eb6b3d {
    border-color: rgba(235, 107, 61, 0.3);

    .icon {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &:hover {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
    border-color: transparent;

    &::before {
      opacity: 1;
      visibility: visible;
    }
    .icon {
      background: $white-color;
      color: $light-green-color;
    }
    h3,
    p {
      color: $white-color;
    }
    &.bg-f78acb {
      .icon {
        color: #f78acb;
      }
    }
    &.bg-c679e3 {
      .icon {
        color: $purple-color;
      }
    }
    &.bg-eb6b3d {
      .icon {
        color: #eb6b3d;
      }
    }
  }
}

/*================================================
About CSS
=================================================*/
.about-content {
  .section-title {
    text-align: left;
    margin-bottom: 18px;

    .bar {
      margin-right: 0;
      margin-left: 0;
    }
    p {
      margin: 0;
    }
  }
}
.about-inner-area {
  margin-top: 60px;

  .about-text {
    h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
}

/*================================================
Why Choose US CSS
=================================================*/
.why-choose-us {
  //background: transparent url(../images/banner-bg1.jpg) right top no-repeat;
  position: relative;
  z-index: 1;

  .section-title {
    text-align: left;

    .bar {
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
}
.why-choose-us-image {
  position: relative;
  text-align: center;

  .main-pic {
    display: none;
  }
  img {
    &:nth-child(1) {
      position: relative;
      left: -20px;
    }
    &:nth-child(3) {
      position: absolute;
      top: -55px;
      right: 0;
      left: 0;
      margin: 0 auto;
      z-index: -1;
      animation-duration: 14s;
    }
  }
  &.single-why-choose-us-image {
    img {
      display: block;
      position: relative;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
.col-lg-6 .col-lg-6 {
  &:nth-child(1) {
    .single-why-choose-us {
      margin-top: 60px;
    }
  }
  &:nth-child(2) {
    .single-why-choose-us {
      margin-top: 0;
    }
  }
  &:nth-child(4) {
    .single-why-choose-us {
      margin-top: -30px;
    }
  }
}
.single-why-choose-us {
  padding: 30px 15px;
  border-radius: 5px;
  text-align: center;
  background: $white-color;
  -webkit-box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
  box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
  -webkit-transition: $transition;
  transition: $transition;
  margin-top: 30px;

  .icon {
    color: $light-green-color;
    -webkit-transition: $transition;
    transition: $transition;

    i {
      &::before {
        font-size: 45px;
      }
    }
  }
  h3 {
    font-size: 18px;
    -webkit-transition: $transition;
    transition: $transition;
    margin: {
      top: 15px;
      bottom: 15px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: $transition;
    transition: $transition;
  }
  p {
    -webkit-transition: $transition;
    transition: $transition;
  }
  &:hover {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
    border-color: transparent;

    &::before {
      opacity: 1;
      visibility: visible;
    }
    .icon {
      color: $white-color;
    }
    h3,
    p {
      color: $white-color;
    }
  }
}

/*================================================
Services CSS
=================================================*/
.services-area {
  overflow: hidden;
}
.services-content {
  .section-title {
    text-align: left;
    margin-bottom: 20px;

    .bar {
      margin: {
        left: 0;
        right: 0;
      }
    }
    p {
      margin: 0;
    }
  }
  .row {
    margin: {
      left: -8px;
      right: -8px;
    }
    .col-lg-6 {
      padding: {
        left: 8px;
        right: 8px;
      }
    }
  }
  .box {
    position: relative;
    margin-top: 20px;
    z-index: 1;
    display: block;
    padding: 15px 12px;
    color: $paragraph-color;
    font-weight: 500;
    background-color: $white-color;
    border-radius: 3px;
    -webkit-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border-radius: 3px;
      z-index: -1;
      -webkit-transition: $transition;
      transition: $transition;
    }
    svg {
      margin-right: 5px;
      display: inline-block;
      color: $light-green-color;
      -webkit-transition: $transition;
      transition: $transition;
      width: 18px;
    }
    &:hover {
      color: $white-color;
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);

      &::before {
        opacity: 1;
        visibility: visible;

        svg {
          color: $white-color;
        }
      }
    }
  }
  .boxButtonHome {
    position: relative;
    margin-top: 20px;
    z-index: 1;
    display: block;
    padding: 15px 12px;
    color: black;
    cursor: pointer;
    font-weight: 500;
    background: $gradient-color3;
    border-radius: 3px;
    -webkit-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $gradient-color3;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border-radius: 3px;
      z-index: -1;
      -webkit-transition: $transition;
      transition: $transition;
    }
    svg {
      margin-right: 5px;
      display: inline-block;
      color: $light-green-color;
      -webkit-transition: $transition;
      transition: $transition;
      width: 18px;
    }
    &:hover {
      color: black;
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);

      &::before {
        opacity: 1;
        visibility: visible;

        svg {
          color: black;
        }
      }
    }
  }

  .ribbonparent {
    overflow: hidden; /* required */
  }

  .ribbon {
    margin: 0;
    padding: 0;
    background: #f86156;
    color: white;
    padding: 0.5rem 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;
  }
  .ribbon:before,
  .ribbon:after {
    content: "";
    position: absolute;
    top: 0;
    margin: 0 -1px; /* tweak */
    width: 100%;
    height: 100%;
    background: #f86156;
  }
  .ribbon:before {
    right: 100%;
  }

  .ribbon:after {
    left: 100%;
  }

  .boxButtonEmployeesOnShift {
    position: relative;
    margin-top: 20px;
    z-index: 1;
    display: block;
    padding: 15px 12px;
    color: black;
    cursor: pointer;
    font-weight: 500;
    background: $gradient-color5;
    border-radius: 3px;
    -webkit-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $gradient-color5;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border-radius: 3px;
      z-index: -1;
      -webkit-transition: $transition;
      transition: $transition;
    }
    svg {
      margin-right: 5px;
      display: inline-block;
      color: $light-green-color;
      -webkit-transition: $transition;
      transition: $transition;
      width: 18px;
    }
    &:hover {
      color: black;
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);

      &::before {
        opacity: 1;
        visibility: visible;

        svg {
          color: black;
        }
      }
    }
  }

  .boxButtonHomeCIGMenuButton {
    position: relative;
    margin-top: 20px;
    z-index: 1;
    display: block;
    padding: 15px 12px;
    color: #ffffff;
    cursor: pointer;
    font-weight: 500;
    background: $gradient-color4;
    border-radius: 5px;
    -webkit-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $gradient-color4;
      opacity: 0%;
      visibility: hidden;
      position: absolute;
      border-radius: 10px;
      z-index: -1;
      -webkit-transition: $transition;
      transition: $transition;
    }
    svg {
      margin-right: 5px;
      display: inline-block;
      color: $light-green-color;
      -webkit-transition: $transition;
      transition: $transition;
      width: 18px;
    }
    &:hover {
      color: #ffffff;
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);

      &::before {
        opacity: 1;
        visibility: visible;

        svg {
          color: #ffffff;
        }
      }
    }
  }
  .boxButtonTasks {
    position: relative;
    margin-top: 20px;
    z-index: 1;
    display: block;
    padding: 15px 12px;
    color: $white-color;
    cursor: pointer;
    font-weight: 500;
    background: $gradient-color;
    border-radius: 3px;
    -webkit-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border-radius: 3px;
      z-index: -1;
      -webkit-transition: $transition;
      transition: $transition;
    }
    svg {
      margin-right: 5px;
      display: inline-block;
      color: $light-green-color;
      -webkit-transition: $transition;
      transition: $transition;
      width: 18px;
    }
    &:hover {
      color: $white-color;
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);

      &::before {
        opacity: 1;
        visibility: visible;

        svg {
          color: $white-color;
        }
      }
    }
  }

  .boxButtonTasksHome {
    position: relative;
    margin-top: 20px;
    z-index: 1;
    display: block;
    padding: 15px 12px;
    color: $white-color;
    font-weight: 500;
    background: $gradient-color;
    border-radius: 3px;
    -webkit-transition: $transition;
    transition: $transition;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    &::before {
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border-radius: 3px;
      z-index: -1;
      -webkit-transition: $transition;
      transition: $transition;
    }
    svg {
      margin-right: 5px;
      display: inline-block;
      color: $light-green-color;
      -webkit-transition: $transition;
      transition: $transition;
      width: 18px;
    }
    &:hover {
      color: $white-color;
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);

      &::before {
        opacity: 1;
        visibility: visible;

        svg {
          color: $white-color;
        }
      }
    }
  }
}
.services-right-image {
  position: relative;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 22%;
      top: -220px;
      z-index: 1;
    }
    /*
        &:nth-child(2) {
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
            top: -52px;
        }
        &:nth-child(3) {
            left: 44%;
            top: -33px;
            z-index: 1;
        }
        &:nth-child(4) {
            right: 38%;
            z-index: 1;
            top: -220px;
        }
        &:nth-child(5) {
            z-index: 1;
            right: 24%;
            top: 8px;
        }
        &:nth-child(6) {
            left: 29%;
            top: 0;
            z-index: 1;
        }
        &:nth-child(7) {
            right: 44%;
            top: -78px;
            z-index: 1;
        }
        &:nth-child(8) {
            right: 30%;
            top: -134px;
            z-index: 1;
        }
        &:nth-child(9) {
            right: 31%;
            top: -21px;
            z-index: 1;
        }
        &:nth-child(10) {
            right: 24%;
            top: -80px;
        }
        &:nth-child(11) {
            left: 27%;
            top: -60px;
            z-index: 2;
        }
        &:nth-child(12) {
            right: 28%;
            z-index: 1;
            top: -54px;
        }
        &:nth-child(13) {
            left: 31%;
            top: -105px;
            z-index: 1;
        }
        */
    &.bg-image {
      top: -262px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
    }
    &:last-child {
      display: none;
    }
  }
  &.single-right-image {
    img {
      display: block;

      &:first-child {
        top: -60px;
      }
      &:last-child {
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
}
.services-right-image-phone {
  position: relative;
  img {
    :last-child {
      display: none;
    }
  }
  & div {
    display: none;
  }
}
.services-left-image {
  position: relative;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 15%;
      top: -220px;
    }
    /*
        &:nth-child(2) {
            left: 0;
            top: -200px;
            right: 0;
            text-align: center;
            margin: 0 auto;
        }
        &:nth-child(3) {
            left: 36%;
            z-index: 12;
            top: -55px;
        }
        &:nth-child(4) {
            left: 55px;
            top: -38px;
            z-index: 1;
        }
        &:nth-child(5) {
            z-index: 3;
            right: 26%;
            top: -66px;
        }
        &:nth-child(6) {
            right: 19%;
            top: -41px;
            z-index: 2;
        }
        &:nth-child(7) {
            right: 97px;
            top: 40px;
            z-index: 1;
        }
        &:nth-child(8) {
            right: 23%;
            top: -175px;
            z-index: 2;
        }
        */
    &.bg-image {
      top: -262px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
    }
    &:last-child {
      display: none;
    }
  }
  &.single-left-image {
    img {
      display: block;

      &:first-child {
        top: -60px;
      }
      &:last-child {
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
}
.services-left-image-phone {
  position: relative;
  img {
    :last-child {
      display: none;
    }
  }
  & div {
    display: none;
  }
}
.single-services-box {
  position: relative;
  background: $white-color;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-transition: $transition;
  transition: $transition;
  padding: {
    left: 20px;
    top: 30px;
    right: 20px;
    bottom: 30px;
  }
  h3 {
    font-size: 18px;
    margin: {
      top: 25px;
      bottom: 15px;
    }
  }
  .icon {
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: $light-green-color;
    -webkit-transition: $transition;
    transition: $transition;

    &.bg-c679e3 {
      color: $purple-color;
      background: #edc3fc;
    }
    &.bg-eb6b3d {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    background: $light-green-color;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    -webkit-transition: $transition;
    transition: $transition;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  &:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      background: $light-green-color;
      color: $white-color;

      &.bg-c679e3 {
        background: $purple-color;
      }
      &.bg-eb6b3d {
        background: #eb6b3d;
      }
    }
    &::after,
    &::before {
      opacity: 1;
      visibility: visible;
      width: 50%;
    }
  }
}
.services-area-two {
  padding-bottom: 50px;
}

/*================================================
Services Details CSS
=================================================*/
.services-details-desc {
  h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .services-details-accordion {
    .accordion {
      list-style-type: none;
      padding: 0;
      margin: 25px 0 0;

      .accordion-item {
        display: block;
        -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        background: $white-color;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .accordion-title {
        display: block;
        padding: 12px 20px 12px 51px;
        text-decoration: none;
        color: $black-color;
        position: relative;
        border-bottom: 1px solid $white-color;
        font: {
          size: 15px;
          weight: 500;
        }
        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          text-align: center;
          height: 100%;
          background: $light-green-color;
          color: $white-color;

          &::before {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 15px;
          }
        }
        &.active {
          border-bottom-color: #e1e1e1;

          i {
            &::before {
              content: "\f10f";
            }
          }
        }
      }
      .accordion-content {
        display: none;
        position: relative;
        padding: 15px 15px;

        &.show {
          display: block;
        }
      }
    }
  }
}
.services-details-area {
  .separate {
    margin-top: 80px;
  }
}
.services-details-image {
  text-align: center;
}

/*================================================
Features CSS
=================================================*/
.features-area {
  padding-bottom: 50px;
}
.features-area-two {
  overflow: hidden;
}
.single-features {
  position: relative;
  border-radius: 5px;
  -webkit-transition: $transition;
  transition: $transition;
  background: $white-color;
  margin-bottom: 30px;
  padding: {
    left: 95px;
    top: 30px;
    right: 20px;
    bottom: 30px;
  }
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .icon {
    position: absolute;
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: $light-green-color;
    left: 20px;
    top: 30px;
    -webkit-transition: $transition;
    transition: $transition;

    &.bg-c679e3 {
      color: $purple-color;
      background: #edc3fc;
    }
    &.bg-eb6b3d {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      background: $light-green-color;
      color: $white-color;

      &.bg-c679e3 {
        background: $purple-color;
      }
      &.bg-eb6b3d {
        background: #eb6b3d;
      }
    }
  }
}
.hosting-features-area {
  padding-bottom: 50px;
}
.single-hosting-features {
  position: relative;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-transition: $transition;
  transition: $transition;
  background: $white-color;
  text-align: center;
  padding: {
    left: 20px;
    top: 30px;
    right: 20px;
    bottom: 30px;
  }
  h3 {
    font-size: 18px;
    margin: {
      top: 25px;
      bottom: 15px;
    }
  }
  .icon {
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    display: inline-block;
    border-radius: 50%;
    color: $light-green-color;
    -webkit-transition: $transition;
    transition: $transition;

    &.bg-c679e3 {
      color: $purple-color;
      background: #edc3fc;
    }
    &.bg-eb6b3d {
      color: #eb6b3d;
      background: rgba(235, 107, 61, 0.3);
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    background: $light-green-color;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    -webkit-transition: $transition;
    transition: $transition;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  &:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .icon {
      background: $light-green-color;
      color: $white-color;

      &.bg-c679e3 {
        background: $purple-color;
      }
      &.bg-eb6b3d {
        background: #eb6b3d;
      }
    }
    &::after,
    &::before {
      opacity: 1;
      visibility: visible;
      width: 50%;
    }
  }
}

/*================================================
Features Details CSS
=================================================*/
.features-details-desc {
  h3 {
    font-size: 20px;
    margin-bottom: 13px;
  }
  .features-details-list {
    padding: 0;
    margin: 20px 0 0;
    list-style-type: none;

    li {
      margin-bottom: 12px;
      color: $paragraph-color;
      position: relative;
      padding-left: 15px;

      &::before {
        background: $light-green-color;
        width: 8px;
        height: 8px;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        content: "";
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .features-details-accordion {
    .accordion {
      list-style-type: none;
      padding: 0;
      margin: 25px 0 0;

      .accordion-item {
        display: block;
        -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        background: $white-color;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .accordion-title {
        display: block;
        padding: 12px 20px 12px 51px;
        text-decoration: none;
        color: $black-color;
        position: relative;
        border-bottom: 1px solid $white-color;
        font: {
          size: 15px;
          weight: 500;
        }
        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          text-align: center;
          height: 100%;
          background: $light-green-color;
          color: $white-color;

          &::before {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 15px;
          }
        }
        &.active {
          border-bottom-color: #e1e1e1;

          i {
            &::before {
              content: "\f10f";
            }
          }
        }
      }
      .accordion-content {
        display: none;
        position: relative;
        padding: 15px 15px;

        &.show {
          display: block;
        }
      }
    }
  }
}
.features-details-area {
  .separate {
    margin-top: 80px;
  }
}
.features-details-image {
  text-align: center;
}

/*================================================
Team CSS
=================================================*/
.team-area {
  padding-bottom: 50px;

  .owl-dots {
    margin-bottom: 30px;
  }
}
.single-team {
  background: $white-color;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 30px;
  -webkit-transition: $transition;
  transition: $transition;
  z-index: 1;
  text-align: center;

  .team-image {
    position: relative;
    z-index: 1;
    padding: {
      left: 35px;
      right: 35px;
    }
    img {
      border: 3px solid #cdf1d8;
      width: auto !important;
      border-radius: 50%;
      display: inline-block !important;
      -webkit-transition: $transition;
      transition: $transition;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background: $bg-color2;
      height: 10px;
      width: 100%;
      z-index: -1;
      -webkit-transition: $transition;
      transition: $transition;
    }
  }
  .team-content {
    padding: {
      left: 35px;
      right: 35px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        display: inline-block;
        margin: 0 5px;

        a {
          color: $purple-color;

          &:hover {
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            color: $light-green-color;
          }
          svg {
            width: 19px;
          }
        }
      }
    }
    p {
      margin: {
        top: 20px;
        bottom: 0;
      }
    }
  }
  .team-info {
    background: $gradient-color;
    -webkit-transition: $transition;
    transition: $transition;
    padding: 10px 15px;
    border-radius: 75px;
    margin: {
      bottom: 25px;
      left: 10px;
      right: 10px;
      top: 25px;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 5px;
      color: $white-color;
    }
    span {
      color: $white-color;
      font-size: 13px;
    }
  }
  &:hover {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .team-image {
      &::before {
        height: 0;
      }
      img {
        border-color: $light-green-color;
      }
    }
    .team-info {
      border-radius: 0;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
}

/*================================================
Works CSS
=================================================*/
.works-area {
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
}
.single-works {
  position: relative;
  margin-bottom: 30px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    -webkit-transition: $transition;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }
  .icon {
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 20px;
    background: $white-color;
    width: 45px;
    text-align: center;
    height: 45px;
    line-height: 45px;
    opacity: 0;
    -webkit-transition: $transition;
    transition: $transition;
    visibility: hidden;
    z-index: 2;

    svg {
      width: 19px;
    }
    &:hover {
      background: $black-color;
      color: $white-color;
    }
  }
  .works-content {
    position: absolute;
    bottom: -60px;
    left: 0;
    padding: 20px;
    -webkit-transition: $transition;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
    z-index: 2;

    h3 {
      font-size: 18px;
      margin-bottom: 15px;

      a {
        color: $white-color;
      }
    }
    p {
      color: $white-color;
    }
  }
  &:hover {
    &::before {
      opacity: 0.8;
      visibility: visible;
    }
    .icon {
      opacity: 1;
      visibility: visible;
      top: 20px;
    }
    .works-content {
      opacity: 1;
      visibility: visible;
      bottom: 0;
    }
  }
}

/*================================================
Fun Facts CSS
=================================================*/
.funfacts-area {
  position: relative;
  z-index: 1;

  .section-title {
    h2 {
      max-width: 520px;
      margin: 0 auto;
      line-height: 30px;
    }
  }
  .map-bg {
    position: absolute;
    z-index: -1;
    top: 120px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
}
.funfact {
  text-align: center;

  h3 {
    color: $light-green-color;
    font-size: 32px;
    margin-bottom: 7px;
  }
  p {
    line-height: initial;
  }
}
.contact-cta-box {
  margin: 80px auto 0;
  max-width: 600px;
  border: 1px dashed #ebebeb;
  padding: 30px 210px 30px 30px;
  border-radius: 5px;
  position: relative;

  h3 {
    font-size: 20px;
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 0;
  }
  .btn {
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -27px;

    &::before,
    &::after {
      background: $light-green-color;
    }
  }
  .btn-primary {
    background: $purple-color;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

    &:hover,
    &:focus {
      -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
      box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    }
  }
}

/*==========================================
Pricing CSS
===========================================*/
.pricing-area {
  position: relative;
  z-index: 1;
}
.pricing-table {
  text-align: center;
  background: $white-color;
  -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding-bottom: 35px;
  -webkit-transition: $transition;
  transition: $transition;

  .pricing-header {
    background: $white-color;
    position: relative;
    padding: 30px 20px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #eeeeee;
    -webkit-transition: $transition;
    transition: $transition;
    margin-bottom: 30px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0 0;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: $transition;
      transition: $transition;
      z-index: -1;
    }
    h3 {
      font-size: 18px;
      text-transform: uppercase;
      -webkit-transition: $transition;
      transition: $transition;
      margin-bottom: 0;
    }
  }
  .price {
    margin-bottom: 30px;

    span {
      color: $light-green-color;
      font: {
        size: 30px;
        weight: 600;
      }
      sup {
        font-weight: 500;
      }
      span {
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }
  .pricing-features {
    ul {
      padding: 0;
      list-style-type: none;
      margin-bottom: 30px;

      li {
        color: #ccdcea;
        margin-bottom: 12px;
        text-decoration: line-through;

        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          color: $paragraph-color;
          text-decoration: none;
        }
      }
    }
  }
  &:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);

    .pricing-header {
      &::before {
        opacity: 1;
        visibility: visible;
      }
      h3 {
        color: $white-color;
      }
    }
  }
  &.active-plan {
    .pricing-header {
      &::before {
        opacity: 1;
        visibility: visible;
      }
      h3 {
        color: $white-color;
      }
    }
    .btn-primary {
      background: $purple-color;
      -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
      box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

      &::after,
      &::before {
        background: $light-green-color;
        -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
        box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
      }
    }
  }
}
.single-pricing-table {
  text-align: center;
  background: $white-color;
  -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 35px;
  -webkit-transition: $transition;
  transition: $transition;

  .pricing-header {
    svg {
      transform: scale(1.7);
    }
    h3 {
      text-transform: uppercase;
      margin: 30px 0 20px;
      font: {
        size: 18px;
        weight: 500;
      }
    }
  }
  .price {
    margin-bottom: 20px;

    span {
      color: $light-green-color;
      font: {
        size: 30px;
        weight: 600;
      }
      sup {
        font-weight: 500;
      }
      span {
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }
  .pricing-features {
    ul {
      padding: 0;
      margin: 0 0 30px;
      list-style-type: none;

      li {
        color: $paragraph-color;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
        svg {
          width: 15px;
        }
      }
    }
  }
  &.active-plan {
    .btn-primary {
      background: $purple-color;
      -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
      box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

      &::after,
      &::before {
        background: $light-green-color;
        -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
        box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
      }
    }
  }
}

/*================================================
Domain Search CSS
=================================================*/
.domain-search-content {
  text-align: center;
  max-width: 750px;
  margin: 0 auto;

  h2 {
    text-transform: capitalize;
    margin-bottom: 30px;
    font: {
      size: 24px;
      weight: 600;
    }
  }
  form {
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    overflow: hidden;
    border-radius: 30px;

    .form-control {
      background-color: $white-color;
      border: none;
      height: 55px;
      padding-left: 20px;
      float: left;
      width: 63%;
    }
    .domain-select {
      float: left;
      width: 15%;
      padding-right: 20px;

      .form-control {
        padding: 0;
        float: unset;
        width: 100%;
        cursor: pointer;
        height: 55px;
      }
    }
    button {
      -webkit-box-shadow: unset !important;
      box-shadow: unset !important;
      float: left;
      width: 22%;
      height: 55px;
      border-radius: 30px;
      background-color: $light-green-color;
      color: $white-color;
      outline: 0;
      cursor: pointer;
      border: none;
      padding: 15px 40px;
      text-transform: uppercase;
      -webkit-transition: $transition;
      transition: $transition;
      font: {
        weight: 600;
        size: 14px;
      }
      &:hover,
      &:focus {
        background-color: $purple-color;
      }
    }
  }
  ul {
    padding: 0;
    margin: 30px 0 0;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    li {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      font-weight: 500;
      border-left: 1px solid #eeeeee;

      &:first-child {
        border-left: none;
      }
    }
  }
}

/*================================================
Feedback CSS
=================================================*/
.feedback-area {
  position: relative;
  z-index: 1;
}
.feedback-slides {
  position: relative;
  max-width: 750px;
  margin: 0 auto;

  .client-feedback {
    position: relative;

    .single-feedback {
      background: $white-color;
      border-radius: 5px;
      position: relative;
      margin-bottom: 60px;
      padding: {
        left: 170px;
        right: 40px;
        top: 40px;
        bottom: 40px;
      }
      .client-img {
        position: absolute;
        left: 40px;
        top: 40px;

        img {
          border-radius: 50%;
          border: 2px solid $light-green-color;
          padding: 3px;
        }
      }
      h3 {
        font-size: 18px;
        margin: 0;
      }
      span {
        display: block;
        color: $purple-color;
        margin: {
          top: 8px;
          bottom: 18px;
        }
      }
      p {
        font-size: 15px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -25px;
        width: 50px;
        height: 50px;
        background: $white-color;
        right: 0;
        margin: 0 auto;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .client-thumbnails {
    position: relative;
    margin: 0 85px;

    .item {
      .img-fill {
        cursor: pointer;
        position: relative;
        text-align: center;

        img {
          opacity: 0.4;
          -webkit-transition: $transition;
          transition: $transition;
          display: inline-block;
          position: relative;
          border: 2px solid $light-green-color;
          border-radius: 50%;
          padding: 3px;
          width: 85px;
        }
      }
      &:hover {
        .img-fill {
          img {
            opacity: 1;
          }
        }
      }
    }
    .slick-center {
      .img-fill {
        img {
          opacity: 1;
        }
      }
    }
  }
  .next-arrow,
  .prev-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: transparent;
    color: #5e5e5e;
    border: 2px solid #5e5e5e;
    z-index: 1;
    border-radius: 50%;
    line-height: 30px;
    outline: 0 !important;
    -webkit-transition: $transition;
    transition: $transition;

    &::before,
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $gradient-color;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: $transition;
      transition: $transition;
      border-radius: 50%;
    }
    svg {
      width: 18px;
      height: 18px;
      position: relative;
    }
    &:hover,
    &:hover {
      color: $white-color;
      border-color: $light-green-color;

      &::before,
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .next-arrow {
    right: -20px;
  }
  .prev-arrow {
    left: -20px;
  }
}
.testimonials-slides {
  background-color: #f7fbfe;
  max-width: 850px;
  margin: 90px auto 30px;
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    left: -30px;
    top: -30px;
    width: 260px;
    height: 260px;
    content: "";
    z-index: -1;
    // background-image: url(../images/layer.png);
  }
  &::after {
    position: absolute;
    right: -30px;
    bottom: -30px;
    width: 260px;
    height: 260px;
    content: "";
    z-index: -1;
    //  background-image: url(../images/layer.png);
  }
  &.owl-theme {
    .owl-nav {
      &.disabled + .owl-dots {
        margin-top: 0;
        position: absolute;
        bottom: 35px;
        right: 0;
        left: 0;
      }
    }
    .owl-dots {
      .owl-dot {
        span {
          width: 18px !important;
          height: 18px !important;
          background: transparent !important;
          border: 2px solid #d6d6d6;
          position: relative;
          margin: 0 3px !important;
          -webkit-transition: $transition;
          transition: $transition;

          &::before {
            background: #d6d6d6;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            -webkit-transition: $transition;
            transition: $transition;
          }
        }
        &:hover,
        &.active {
          span {
            border-color: $light-green-color;

            &::before {
              background: $light-green-color;
            }
          }
        }
      }
    }
  }
}
.single-feedback-item {
  padding: 40px 130px 90px;
  text-align: center;
  background-color: #f7fbfe;
  position: relative;

  .client-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: left;
    max-width: 280px;
    margin: 0 auto 25px;

    .image {
      padding-right: 20px;

      img {
        display: inline-block !important;
        width: 75px;
        border-radius: 50%;
        border: 3px solid $light-green-color;
      }
    }
    .title {
      h3 {
        font-size: 18px;
        margin: 0;
      }
      span {
        display: block;
        margin-top: 8px;
        color: $purple-color;
      }
    }
  }
  p {
    font: {
      size: 16px;
      style: italic;
    }
  }
  &::before {
    content: "\f11b";
    color: #d9dcde;
    position: absolute;
    top: 0;
    left: 25px;
    font: {
      family: Flaticon;
      size: 30px;
      style: normal;
      size: 65px;
    }
  }
}

/*================================================
Ready To Talk CSS
=================================================*/
.ready-to-talk {
  text-align: center;
  position: relative;
  background: $gradient-color;
  padding: {
    top: 80px;
    bottom: 160px;
  }
  h3 {
    font-size: 24px;
    color: $white-color;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  p {
    color: $white-color;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .btn {
    &::before,
    &::after {
      background: $light-green-color;
    }
  }
  .btn-primary {
    background: $purple-color;
    -webkit-box-shadow: 0 5px 20px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 5px 20px 0 rgba(198, 121, 227, 0.25);

    &::before,
    &::after {
      background-color: $white-color;
    }
    &:hover,
    &:focus {
      color: $black-color;
      -webkit-box-shadow: 0 5px 20px 0 rgba(68, 206, 111, 0.25);
      box-shadow: 0 5px 20px 0 rgba(68, 206, 111, 0.25);
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    & .show > &.dropdown-toggle {
      color: $black-color;
    }
  }
  span {
    a {
      margin-left: 15px;
      color: $white-color;
      text-decoration: underline;
    }
  }
}

/*================================================
Partner CSS
=================================================*/
.partner-section {
  text-align: center;
  position: relative;
  margin-top: -120px;
  background: transparent;

  h5 {
    color: $white-color;
    margin-bottom: 0;
    font: {
      size: 20px;
      weight: 400;
    }
  }
  .partner-inner {
    background: $white-color;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    margin-top: 25px;
    padding: {
      top: 5px;
      left: 30px;
      right: 30px;
      bottom: 30px;
    }
    a {
      display: block;
      margin-top: 25px;
      position: relative;

      img {
        &:last-child {
          position: absolute;
          top: 15px;
          left: 0;
          right: 0;
          margin: 0 auto;
          opacity: 0;
          visibility: hidden;
          -webkit-transition: $transition;
          transition: $transition;
        }
      }
      &:hover {
        img {
          &:last-child {
            opacity: 1;
            top: 0;
            visibility: visible;
          }
        }
      }
    }
  }
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
  padding-bottom: 50px;

  .pagination-area {
    margin-bottom: 30px;
  }
}
.single-blog-post {
  position: relative;
  -webkit-transition: $transition;
  transition: $transition;
  margin-bottom: 30px;

  .blog-image {
    position: relative;

    a {
      display: block;
    }
    .date {
      position: absolute;
      left: 20px;
      bottom: -20px;
      background: $gradient-color;
      color: $white-color;
      padding: 8px 16px;
      font-size: 13px;
      border-radius: 50px;

      svg {
        width: 15px;
        margin-right: 3px;
      }
    }
  }
  .blog-post-content {
    background: $bg-color;
    -webkit-transition: $transition;
    transition: $transition;
    padding: {
      top: 40px;
      left: 20px;
      bottom: 20px;
      right: 20px;
    }
    h3 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0;
    }
    span {
      display: block;
      color: $black-color;
      margin: 15px 0;

      a {
        color: $paragraph-color;
        margin-left: 2px;

        &:hover {
          color: $light-green-color;
        }
      }
    }
    .read-more-btn {
      color: $black-color;

      svg {
        -webkit-transition: $transition;
        transition: $transition;
        width: 15px;
      }
      &:hover {
        color: $light-green-color;

        svg {
          margin-left: 5px;
        }
      }
    }
  }
  &:hover {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .blog-post-content {
      background: $white-color;
    }
  }
}

/*================================================
Shop CSS
=================================================*/
.woocommerce-topbar {
  background-color: #fcfbfb;
  padding: 10px 30px;
  margin-bottom: 30px;

  .woocommerce-topbar-ordering {
    .form-control {
      cursor: pointer;
      background-color: transparent;
      border-color: #ebebeb;
      border-radius: 4px;
    }
  }
}
.single-products {
  text-align: center;
  position: relative;
  margin-bottom: 30px;

  .products-content {
    margin-top: 20px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    ul {
      padding: 0;
      margin: 10px 0 12px;
      list-style-type: none;

      li {
        display: inline-block;
        color: #eac11d;

        i {
          &::before {
            font-size: 15px;
          }
        }
      }
    }
    .add-to-cart-btn {
      display: block;
      width: 100%;
      color: $black-color;
      border: 1px solid $black-color;
      padding: 10px 15px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .sale-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #ff2d2d;
    color: #fff;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    z-index: 2;
  }
  .products-image {
    overflow: hidden;
    position: relative;

    img {
      -webkit-transition: all 1.5s ease-out;
      transition: all 1.5s ease-out;
      width: 100%;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: gray;
      content: "";
      -webkit-transition: $transition;
      transition: $transition;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
    }
    ul {
      padding: 0;
      z-index: 2;
      list-style-type: none;
      margin: 0;
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      -webkit-transition: $transition;
      transition: $transition;

      li {
        display: inline-block;
        margin: {
          right: 3px;
          left: 3px;
        }
        a {
          display: block;
          background: $white-color;
          width: 35px;
          height: 35px;
          line-height: 35px;

          svg {
            width: 20px;
          }
          &:hover,
          &:focus {
            background-color: $light-green-color;
            color: $white-color;
          }
        }
        &:nth-child(3) {
          a {
            margin-left: -15px;
          }
        }
        &:nth-child(1) {
          a {
            margin-right: -15px;
          }
        }
      }
    }
  }
  &:hover {
    .products-content {
      .add-to-cart-btn {
        background-color: $black-color;
        color: $white-color;
      }
    }
    .products-image {
      img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
      &::before {
        opacity: 0.4;
        visibility: visible;
      }
      ul {
        opacity: 1;
        visibility: visible;

        li {
          &:nth-child(3) {
            a {
              margin-left: 0;
            }
          }
          &:nth-child(1) {
            a {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.modal-dialog {
  max-width: 900px;
}
.modal {
  background-color: rgba(0, 0, 0, 0.75);
}
.modal-content {
  border: none;
  border-radius: 0;

  button {
    &.close {
      position: absolute;
      right: 2%;
      display: inline-block;
      opacity: 1;
      top: 2%;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 32px;
      border: 1px solid #eeeeee;
      border-radius: 50%;
      outline: 0;
      -webkit-transition: $transition;
      transition: $transition;

      span {
        -webkit-transition: $transition;
        transition: $transition;
      }
      &:hover,
      &:focus {
        background-color: $light-green-color;
        color: $white-color;

        span {
          color: $white-color;
        }
      }
    }
  }
  .products-image {
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .products-content {
    p {
      margin-bottom: 20px;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .price {
      margin-bottom: 15px;

      span {
        text-decoration: line-through;
        color: $paragraph-color;
      }
    }
    form {
      .quantity {
        display: inline-block;

        input {
          border: 1px solid #eeeeee;
          border-left: none;
          border-right: none;
          background: $white-color;
          height: 45px;
          padding: 15px;
          width: 50px;
          outline: 0;
          font-size: 20px;
        }
        .minus-btn {
          height: 45px;
          display: inline-block;
          line-height: 47px;
          padding-right: 0;
          border: 1px solid #eeeeee;
          border-right: none;
          cursor: pointer;
          padding-left: 10px;
          position: relative;
          top: -1px;

          span {
            svg {
              width: 20px;
              height: 16px;
            }
          }
        }
        .plus-btn {
          height: 45px;
          display: inline-block;
          line-height: 45px;
          padding-right: 10px;
          border: 1px solid #eeeeee;
          border-left: none;
          cursor: pointer;
          padding-right: 8px;
          position: relative;
          top: -2px;

          svg {
            width: 20px;
            height: 16px;
          }
        }
      }
      button {
        background: $light-green-color;
        color: $white-color;
        border: none;
        height: 46px;
        padding: 0 20px;
        position: relative;
        top: -3px;
        cursor: pointer;
        margin-left: 10px;
        outline: 0;
        -webkit-transition: $transition;
        transition: $transition;

        &:hover,
        &:focus {
          background-color: $purple-color;
          color: $white-color;
        }
      }
    }
    .product-meta {
      margin-top: 15px;

      span {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}

/*================================================
Shop Details CSS
=================================================*/
.products-details-image {
  img {
    width: 100%;
  }
  .slick-slider {
    position: relative;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .slick-dots {
    padding: 0;
    text-align: center;
    margin: 10px 0 0;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    li {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin: 0 5px;

      button {
        padding: 0;
        outline: 0;
        border: none;
        margin: 0;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .slick-thumbs {
    cursor: pointer;

    .slick-slide {
      padding: 0 5px;

      &:first-child {
        margin-left: -5px;
      }
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
}
.payment-methods {
  img {
    margin-right: 5px;
  }
}
.products-details {
  h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .price {
    margin-bottom: 15px;

    span {
      text-decoration: line-through;
      color: $paragraph-color;
    }
  }
  .rating {
    padding: 0;
    margin: 0 0 12px;
    list-style-type: none;

    li {
      display: inline-block;
      color: #eac11d;
      margin-right: 3px;

      i {
        &::before {
          font-size: 15px;
        }
      }
    }
  }
  .availability {
    margin-bottom: 20px;

    span {
      color: $light-green-color;
    }
  }
  form {
    .quantity {
      display: inline-block;
      padding-right: 10px;

      span {
        padding-right: 10px;
      }
      input {
        border: 1px solid #eeeeee;
        background: $white-color;
        height: 45px;
        width: 50px;
        outline: 0;
        text-align: center;
        font-size: 20px;
        border: {
          left: none;
          right: none;
        }
      }
      .minus-btn {
        height: 45px;
        display: inline-block;
        line-height: 45px;
        padding-right: 0;
        border: 1px solid #eeeeee;
        border-right: none;
        cursor: pointer;
        padding-left: 10px;
        position: relative;
        top: -1px;

        span {
          svg {
            width: 20px;
            height: 16px;
          }
        }
      }
      .plus-btn {
        height: 45px;
        display: inline-block;
        line-height: 45px;
        padding-right: 10px;
        border: 1px solid #eeeeee;
        border-left: none;
        cursor: pointer;
        padding-right: 8px;
        position: relative;
        top: -1px;

        svg {
          width: 20px;
          height: 16px;
        }
      }
    }
    button {
      background: $light-green-color;
      color: $white-color;
      border: none;
      height: 46px;
      padding: 0 20px;
      position: relative;
      top: -3px;
      cursor: pointer;
      outline: 0;
      -webkit-transition: $transition;
      transition: $transition;

      &:hover,
      &:focus {
        background-color: $purple-color;
        color: $white-color;
      }
    }
    .add-to-wishlist-btn {
      margin-left: 5px;
      background: transparent;
      color: $black-color;
      border: 1px solid #eeeeee;
      position: relative;
      top: -3px;
      cursor: pointer;
      outline: 0;
      -webkit-transition: $transition;
      transition: $transition;
      width: 45px;
      height: 45px;
      text-align: center;
      line-height: 42px;

      &:hover,
      &:focus {
        background-color: $purple-color;
        color: $white-color;
        border-color: $purple-color;
      }
    }
    .buy-btn {
      margin-top: 20px;

      .btn-primary {
        background: $purple-color;
        -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
        box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

        &::after,
        &::before {
          background: $light-green-color;
          -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
          box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
        }
      }
    }
  }
  .custom-payment-options {
    margin-top: 20px;

    span {
      display: block;
      margin-bottom: 10px;
    }
  }
  .products-share-social {
    margin-top: 25px;

    span {
      display: inline-block;
      margin-right: 10px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: inline-block;

      li {
        display: inline-block;
        margin-right: 5px;

        a {
          width: 32px;
          text-align: center;
          line-height: 29px;
          height: 32px;
          border: 1px solid $light-green-color;
          border-radius: 50%;
          color: $light-green-color;

          svg {
            width: 15px;
          }
          &.facebook {
            border-color: #4267b2;
            color: #4267b2;

            &:hover {
              background: #4267b2;
              color: $white-color;
            }
          }
          &.twitter {
            border-color: #38a1f3;
            color: #38a1f3;

            &:hover {
              background: #38a1f3;
              color: $white-color;
            }
          }
          &.linkedin {
            border-color: #0077b5;
            color: #0077b5;

            &:hover {
              background: #0077b5;
              color: $white-color;
            }
          }
          &.instagram {
            border-color: #231f20;
            color: #231f20;

            &:hover {
              background: #231f20;
              color: $white-color;
            }
          }
          &:hover {
            background: $light-green-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.products-details-tabs {
  margin-top: 40px;
  text-align: center;

  #tabs {
    padding: 0;
    margin: 0 0 30px;
    list-style-type: none;

    li {
      display: inline-block;
      padding: 12px 50px 8px;
      cursor: pointer;
      margin-right: -4px;
      -webkit-transition: $transition;
      transition: $transition;
      background: #eaeaea;
      font-weight: 500;

      &.active {
        background-color: $white-color;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          top: 0;
          left: 0;
          background: $light-green-color;
        }
      }
      &.inactive {
        background-color: #eeeeee;
        padding: 12px 50px;
      }
    }
  }
  .content {
    display: none;

    &.show {
      display: block;
    }
    h3 {
      margin-bottom: 12px;
      font-size: 18px;
    }
    .additional-information {
      padding: 0;
      margin: 25px 0 0;
      list-style-type: none;
      text-align: left;

      li {
        border: 1px solid #eeeeee;
        border-bottom: none;
        padding: 10px 15px;
        color: $paragraph-color;

        &:last-child {
          border-bottom: 1px solid #eeeeee;
        }
        span {
          display: inline-block;
          width: 45%;
        }
      }
    }
    .products-reviews {
      text-align: left;
      max-width: 700px;
      margin: 0 auto;

      p {
        margin-bottom: 0;
      }
      .review-form {
        margin-top: 20px;
        max-width: 620px;

        p {
          margin-bottom: 0;
        }
        .form-control {
          background: transparent;
        }
      }
      .star-source {
        width: 0;
        height: 0;
        margin-top: 5px;
        visibility: hidden;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0 0.15rem;
        }
      }
      .star-rating {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 105px;
        margin-bottom: 10px;

        label {
          cursor: pointer;

          .star {
            color: transparent;
            -webkit-transition: color 0.2s ease-in-out;
            transition: color 0.2s ease-in-out;
          }
        }
        label:hover ~ label .star,
        svg.star:hover,
        input[name="star"]:focus ~ label .star,
        input[name="star"]:checked ~ label .star {
          color: #f2b01e;
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0 0.15rem;
        }
        input[name="star"] {
          display: inline-block;
          width: 0;
          opacity: 0;
          margin-left: -2px;

          &:checked + label {
            -webkit-animation: scaleup 1s;
            animation: scaleup 1s;
          }
          &:checked + label .star {
            -webkit-animation: starred 0.5s;
            animation: starred 0.5s;
          }
        }
      }
    }
  }
}
@-webkit-keyframes scaleup {
  from {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scaleup {
  from {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes starred {
  from {
    color: #f2b01e;
  }
  to {
    color: #f2b01e;
  }
}
@keyframes starred {
  from {
    color: #f2b01e;
  }
  to {
    color: #f2b01e;
  }
}

/*================================================
Cart CSS
=================================================*/
.cart-table {
  table {
    margin-bottom: 0;

    thead {
      tr {
        th {
          border-bottom-width: 0px;
          vertical-align: middle;
          border: none;
          padding: 15px 0;
          font: {
            size: 15px;
            weight: 500;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          color: $paragraph-color;
          padding: {
            left: 0;
            right: 0;
          }
          border: {
            color: #eaedff;
            left: none;
            right: none;
          }
          &.product-thumbnail {
            a {
              display: block;

              img {
                width: 80px;
              }
            }
          }
          &.product-name {
            a {
              color: $paragraph-color;
              font-weight: 500;
              display: inline-block;

              &:hover {
                color: $light-green-color;
              }
            }
          }
          &.product-subtotal {
            .remove {
              color: red;
              margin-left: 25px;
              position: relative;
              top: -1px;

              svg {
                width: 19px;
              }
            }
          }
          &.product-quantity {
            .form-control {
              background: transparent;
            }
          }
          a {
            svg {
              color: red;
              margin: {
                top: -2px;
                left: 9px;
              }
            }
          }
        }
      }
    }
  }
}
.cart-buttons {
  margin-top: 30px;

  .btn-light {
    background-color: #eaedff;

    &:hover,
    &:focus {
      color: $white-color;
    }
    &:hover:not(:disabled):not(.disabled).active,
    &:hover:not(:disabled):not(.disabled):active,
    &:hover .show > &.dropdown-toggle,
    &:focus:not(:disabled):not(.disabled).active,
    &:focus:not(:disabled):not(.disabled):active,
    &:focus .show > .btn-light.dropdown-toggle {
      color: $white-color;
    }
    &:focus {
      color: $white-color;
    }
  }
}
.cart-totals {
  background: $white-color;
  padding: 40px;
  max-width: 600px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: 45px auto 0;

  h3 {
    font-size: 18px;
    margin-bottom: 25px;
  }
  ul {
    padding: 0;
    margin: 0 0 25px;
    list-style-type: none;

    li {
      border: 1px solid #eaedff;
      padding: 10px 15px;
      color: $black-color;
      overflow: hidden;

      &:first-child {
        border-bottom: none;
      }
      &:last-child {
        border-top: none;
      }
      span {
        float: right;
        color: $paragraph-color;
      }
    }
  }
}

/*================================================
Checkout CSS
=================================================*/
.user-actions {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 18px 25px 15px;
  margin-bottom: 35px;
  border-top: 3px solid $light-green-color;

  svg {
    color: $light-green-color;
    margin-right: 4px;
    width: 16px;
  }
  span {
    display: inline-block;
    color: $light-green-color;

    a {
      font-weight: 500;
      display: inline-block;
      color: $black-color;

      &:hover,
      &:focus {
        color: $light-green-color;
      }
    }
  }
}
.input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;

  span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: #d0d0d0;
    width: 40px;
    height: 100%;
    line-height: 50px;
    -webkit-transition: $transition;
    transition: $transition;

    &.minus-btn {
      left: 0;
    }
    &.plus-btn {
      right: 0;
    }
    &:hover {
      color: $black-color;
    }
  }
  input {
    height: 45px;
    color: #000000;
    outline: 0;
    display: block;
    border: none;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
    font: {
      size: 17px;
      weight: 600;
    }
    &::placeholder {
      color: $black-color;
    }
  }
}
.checkout-area {
  .title {
    font-size: 18px;
    margin-bottom: 25px;
  }
}
.billing-details {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 25px;
  border-radius: 5px;

  .form-group {
    label {
      display: block;
      color: $paragraph-color;
      margin-bottom: 8px;
      font: {
        size: 14px;
        weight: 400;
      }
      .required {
        color: red;
      }
    }
    .form-control {
      background: transparent;
    }
    select {
      cursor: pointer;
    }
  }
  .form-check {
    margin-bottom: 15px;

    .form-check-label {
      color: $paragraph-color;
    }
    label {
      font: {
        size: 14px;
        weight: 400;
      }
    }
  }
}
.order-details {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 25px;
  border-radius: 5px;

  .order-table {
    table {
      margin-bottom: 0;
      text-align: left;
    }
    table {
      thead {
        tr {
          th {
            border-bottom-width: 0px;
            vertical-align: middle;
            font-weight: 500;
            border-color: #eaedff;
            padding: 15px 20px;
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            color: $paragraph-color;
            border-color: #eaedff;
            font-size: 14px;

            &.product-name {
              a {
                color: $paragraph-color;
                display: inline-block;

                &:hover {
                  color: $light-green-color;
                }
              }
            }
            &.order-subtotal,
            &.order-shipping,
            &.total-price {
              span {
                color: $black-color;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
  .payment-method {
    margin-top: 40px;
    border-top: 1px solid #eaedff;
    padding-top: 35px;

    p {
      font-size: 14px;

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        display: none;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        padding-left: 27px;
        cursor: pointer;
        display: block;
        font-weight: 600;
        color: $black-color;
        position: relative;
        margin-bottom: 8px;
      }
      [type="radio"]:checked + label::before,
      [type="radio"]:not(:checked) + label::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 18px;
        height: 18px;
        border: 1px solid #dddddd;
        border-radius: 50%;
        background: $white-color;
      }
      [type="radio"]:checked + label::after,
      [type="radio"]:not(:checked) + label::after {
        content: "";
        width: 12px;
        height: 12px;
        background: $light-green-color;
        position: absolute;
        top: 7px;
        left: 3px;
        border-radius: 50%;
        -webkit-transition: $transition;
        transition: $transition;
      }
      [type="radio"]:not(:checked) + label::after {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label::after {
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  .btn {
    &.order-btn {
      display: block;
      margin-top: 25px;
      width: 100%;
      box-shadow: unset;

      &.btn-disabled {
        background-color: #717070;
        border-color: #717070;
        cursor: not-allowed;
        width: 100%;
      }
    }
  }
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  // background: transparent url(../images/banner-bg1.jpg) right top no-repeat;
  text-align: center;
  padding: {
    top: 190px;
    bottom: 100px;
  }
  h2 {
    margin: 0;
    font: {
      size: 24px;
      weight: 700;
    }
  }
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion {
  -webkit-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  background: $white-color;
  border-radius: 10px;

  .accordion {
    border: none;
    border-radius: 0;

    .accordion__item {
      display: block;
      margin-bottom: 15px;
      border: none !important;

      &:last-child {
        margin-bottom: 0;
      }
      .accordion__heading {
        outline: 0 !important;
      }
      .accordion__button {
        display: block;
        padding: 12px 20px;
        text-decoration: none;
        color: $black-color;
        position: relative;
        border-bottom: 1px solid $white-color;
        background: #f5f9ff;
        font-size: 16px;
        font-weight: 500;
        outline: 0 !important;
      }
      .accordion__panel {
        position: relative;
        overflow: hidden;
        padding: 15px 0 5px;
        line-height: 1.9;
      }
    }
  }
}
.faq-contact {
  margin-top: 45px;
  -webkit-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  border-radius: 4px;
  background: $white-color;

  h3 {
    font-size: 24px;
    margin-bottom: 25px;
  }
  form {
    .btn {
      margin-top: 10px;
    }
  }
}

/*=============================
Pagination CSS
===============================*/
.pagination-area {
  text-align: center;
  margin-top: 40px;

  ul {
    padding: 0;
    margin: 0;

    .page-item {
      .page-link {
        padding: 11px 20px;
        margin: 0 -1px;
        color: #6f8ba4;
        background-color: $white-color;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
        box-shadow: 0 2px 10px 0 #d8dde6;

        &:hover,
        &:focus {
          color: $white-color;
          background-color: $light-green-color;
          -webkit-box-shadow: unset;
          box-shadow: unset;
        }
        svg {
          width: 16px;
        }
      }
      &.active {
        .page-link {
          color: $white-color;
          background-color: $light-green-color;
        }
      }
    }
  }
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details-area {
  position: relative;

  .blog-details {
    -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    background: $white-color;

    .article-img {
      position: relative;

      .date {
        position: absolute;
        bottom: 15px;
        width: 100px;
        right: 15px;
        height: 100px;
        text-align: center;
        font-size: 24px;
        font-style: italic;
        background: $light-green-color;
        color: $white-color;
        border-radius: 5px;
        padding-top: 18px;
        line-height: 30px;
      }
    }
    .article-content {
      padding: 30px;

      ul {
        li {
          display: inline-block;
          margin-right: 5px;
        }
        &.category {
          margin-bottom: 25px;

          li {
            a {
              background: $light-green-color;
              color: $white-color;
              padding: 5px 15px 3px;
              display: inline-block;
              border-radius: 3px;
            }
          }
        }
      }
      h3 {
        font-size: 18px;
      }
      p {
        margin: {
          bottom: 0;
          top: 18px;
        }
      }
      .blockquote {
        background: $bg-color;
        padding: 25px;
        border-left: 4px solid $light-green-color;
        margin: {
          bottom: 20px;
          top: 20px;
        }
        p {
          margin-top: 0;
          font-size: 17px;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
      }
      .share-post {
        text-align: center;
        margin-top: 30px;

        ul {
          li {
            a {
              width: 40px;
              height: 40px;
              display: inline-block;
              line-height: 38px;
              border: 1px solid #eeeeee;
              border-radius: 50%;
              color: $black-color;

              &:hover,
              &:focus {
                color: $white-color;
                border-color: $light-green-color;
                background-color: $light-green-color;
              }
              svg {
                width: 16px;
              }
            }
          }
        }
      }
    }
  }
  .post-controls-buttons {
    overflow: hidden;
    background: $white-color;
    padding: 30px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    margin: {
      top: 25px;
      bottom: 25px;
    }
    .controls-left {
      float: left;
    }
    .controls-right {
      float: right;
    }
    div {
      a {
        border: 1px solid #eeeeee;
        padding: 8px 20px;
        display: inline-block;
        border-radius: 5px;

        &:hover,
        &:focus {
          color: $white-color;
          background: $light-green-color;
          border-color: $light-green-color;
        }
      }
    }
  }
  .post-comments {
    padding: 30px;
    background: $white-color;
    -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);

    h3 {
      font-size: 18px;
      margin-bottom: 0;
    }
    .single-comment {
      position: relative;
      padding-left: 95px;
      margin-top: 30px;

      .comment-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 75px;
        height: 75px;

        img {
          border-radius: 50%;
        }
      }
      .comment-content {
        h4 {
          font-size: 14px;
          margin-bottom: 10px;
          text-transform: capitalize;
        }
        span {
          color: $paragraph-color;
        }
        p {
          margin-bottom: 15px;
        }
        a {
          position: absolute;
          right: 0;
          bottom: 0;
          text-transform: uppercase;
        }
      }
      &.left-m {
        margin-left: 85px;
      }
    }
  }
  .leave-a-reply {
    padding: 30px;
    background: $white-color;
    -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
      0 7px 18px rgba(0, 9, 128, 0.05);
    margin-top: 25px;

    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

/*================================================
Project Details CSS
=================================================*/
.project-details-image {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $black-color;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: $transition;
    transition: $transition;
  }
  svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    color: $white-color;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    margin-top: 25px;
    transition: 0.5s;
    cursor: pointer;
    width: 35px;
    height: 35px;

    &:hover {
      color: $light-green-color;
    }
  }
  &:hover {
    &::before {
      opacity: 0.9;
      visibility: visible;
    }
    svg {
      opacity: 1;
      visibility: visible;
      margin-top: -5px;
    }
  }
}
.project-details-desc {
  h3 {
    font-size: 20px;
    margin: {
      bottom: 15px;
      top: 15px;
    }
  }
  .project-details-information {
    overflow: hidden;

    .single-info-box {
      float: left;
      width: 20%;
      margin-top: 30px;

      h4 {
        font-size: 18px;
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 0;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          display: inline-block;
          margin-right: 5px;

          a {
            margin-right: 4px;
            color: $paragraph-color;

            svg {
              width: 18px;
            }
            &:hover {
              color: $light-green-color;
              -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
            }
          }
        }
      }
    }
  }
}

/*================================================
Contact CSS
=================================================*/
.contact-info-box {
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: $white-color;
  padding: 30px;

  .icon {
    display: inline-block;
    width: 65px;
    height: 65px;
    line-height: 62px;
    background: $bg-color;
    border-radius: 50%;
    font-size: 30px;
    color: $light-green-color;
    -webkit-transition: $transition;
    transition: $transition;
  }
  h3 {
    font-size: 18px;
    margin: 25px 0 15px;
  }
  p {
    margin-bottom: 0;

    a {
      color: $paragraph-color;

      &:hover {
        color: $light-green-color;
      }
    }
  }
  &:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);

    .icon {
      color: $white-color;
      background: $light-green-color;
    }
  }
}
#contactForm {
  .btn {
    margin-top: 10px;
  }
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;
  height: 100vh;
  background: {
    // image: url(../images/coming-soon.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    z-index: -1;
    background: linear-gradient(-9deg, #6a82fb 0%, #6a82fb 40%, #fc5c7d 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.8;
  }
  .social-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    bottom: 30px;

    li {
      display: inline-block;
      margin: {
        left: 3px;
        right: 3px;
      }
      &.list-heading {
        display: block;
        color: $white-color;
        margin-bottom: 10px;
        font: {
          size: 16px;
          weight: 500;
        }
      }
      a {
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: $light-green-color;
        color: $white-color;
        border-radius: 50%;

        svg {
          width: 15px;
        }
        &.facebook {
          background-color: #3b5998;
        }
        &.twitter {
          background-color: #1da1f2;
        }
        &.linkedin {
          background-color: #0077b5;
        }
        &.instagram {
          background-color: #c13584;
        }
        &:hover,
        &:focus {
          background-color: $light-green-color;
        }
      }
    }
  }
}
.coming-soon-content {
  text-align: center;
  max-width: 820px;
  margin: -80px auto 0;

  h1 {
    color: $white-color;
    margin-bottom: 15px;
    font: {
      size: 55px;
      weight: 600;
    }
  }
  p {
    color: $white-color;
    font-size: 16px;
    margin: 0 auto;
    max-width: 620px;
  }
  form {
    position: relative;
    margin: 35px auto 55px;
    max-width: 520px;

    .email-input {
      display: block;
      width: 100%;
      height: 56px;
      border: none;
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1),
        0px 0px 0px 5px rgba(255, 255, 255, 0.3);
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1),
        0px 0px 0px 5px rgba(255, 255, 255, 0.3);
      border-radius: 40px;
      padding: 15px 25px;
      font-size: 16px;
      outline: 0 !important;
      background: #f1f2f3;
    }
    .submit-btn {
      position: absolute;
      right: 3px;
      top: 3px;
      height: 50px;
      background: $light-green-color;
      color: $white-color;
      border: none;
      border-radius: 40px;
      width: 130px;
      outline: 0 !important;
      cursor: pointer;
      text-transform: uppercase;
      -webkit-transition: $transition;
      transition: $transition;
      font: {
        size: 15px;
        weight: 500;
      }
      &:hover,
      &:focus {
        background-color: $purple-color;
      }
    }
  }
  #timer {
    div {
      background: $white-color;
      display: inline-block;
      border-radius: 50%;
      width: 110px;
      height: 110px;
      color: $purple-color;
      -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
      margin: 0 10px;
      padding-top: 18px;
      font: {
        size: 35px;
        weight: 500;
      }
      span {
        display: block;
        margin-top: -4px;
        color: $paragraph-color;
        font-size: 15px;
      }
    }
  }
}

/*================================================
404 Error CSS
=================================================*/
.error-area {
  height: 100vh;
}
.error-content {
  text-align: center;
  margin-top: -70px;

  .notfound-404 {
    position: relative;
    height: 280px;
    z-index: -1;
    margin-bottom: 30px;

    h1 {
      margin: 0;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      //   background: url(../images/oops-bg.jpg) no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: cover;
      background-position: center;
      font: {
        size: 230px;
        weight: 900;
      }
    }
  }
  h3 {
    margin-bottom: 15px;
    font: {
      weight: 600;
      size: 25px;
    }
  }
  p {
    max-width: 550px;
    margin: 0 auto 25px;
  }
}

/*================================================
CTA CSS
=================================================*/
.cta-area {
  background: $gradient-color;

  .container {
    max-width: 945px;
  }
}
.cta-content {
  h3 {
    color: $white-color;
    margin: 0;
    font: {
      size: 24px;
      weight: 600;
    }
  }
}
.cta-right-content {
  text-align: right;

  .hosting-price {
    display: inline-block;

    span {
      color: #f6fe6a;
      display: block;
    }
    h4 {
      margin: 10px 0 0;
      color: $white-color;
      font-size: 20px;
    }
  }
  .buy-btn {
    display: inline-block;
    padding-left: 35px;
    position: relative;
    top: -15px;

    .btn-primary {
      background: $purple-color;
      -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
      box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);

      &::after,
      &::before {
        background: $white-color;
        -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
        box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
      }
      &:hover,
      &:focus {
        color: $light-green-color;
      }
    }
  }
}

/*================================================
Repair Main Banner CSS
=================================================*/
.repair-main-banner {
  overflow-x: hidden;
  padding: {
    top: 200px;
    bottom: 140px;
  }
}
.repair-banner-content {
  h1 {
    margin-bottom: 20px;
    font: {
      size: 45px;
      weight: 500;
    }
  }
  p {
    margin-bottom: 30px;
  }
}
.repair-banner-image {
  position: relative;
  text-align: center;

  img {
    &:nth-child(2) {
      position: absolute;
      left: 0;
      top: -65px;
      right: -30px;
      margin: 0 auto;
      z-index: -1;
    }
    &:nth-child(3) {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -69px;
      z-index: -2;
    }
    &:nth-child(4) {
      position: absolute;
      left: 0;
      right: 0;
      top: -30px;
      z-index: -1;
      margin: 0 auto;
      animation-duration: 14s;
    }
  }
}

/*================================================
Repair Partner CSS
=================================================*/
.repair-partner-area {
  padding: {
    top: 45px;
    bottom: 45px;
  }
}
.single-repair-partner {
  text-align: center;

  a {
    display: block;
    position: relative;

    img {
      width: auto !important;
      display: inline-block !important;

      &:last-child {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        -webkit-transition: $transition;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:hover,
    &:focus {
      img {
        &:last-child {
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
    }
  }
}
.partner-slides {
  &.owl-theme {
    .owl-dots {
      margin-top: 0;
    }
  }
}

/*================================================
Repair About CSS
=================================================*/
.repair-about-content {
  .sub-title {
    text-transform: uppercase;
    color: $light-green-color;
    display: block;
    font: {
      size: 15px;
      weight: 400;
    }
  }
  h2 {
    font: {
      size: 24px;
      weight: 600;
    }
    margin: {
      top: 10px;
      bottom: 10px;
    }
  }
  ul {
    padding: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
      top: -10px;
      right: -10px;
      left: -10px;
    }
    li {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;
      margin-top: 25px;
      color: $paragraph-color;
      position: relative;
      padding: {
        right: 10px;
        left: 10px;
      }
      span {
        display: block;
        position: relative;
        z-index: 1;
        padding: 10px;
        border-radius: 3px;
        -webkit-transition: $transition;
        transition: $transition;
        -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

        svg {
          margin-right: 5px;
          display: inline-block;
          color: $light-green-color;
          -webkit-transition: $transition;
          transition: $transition;
          width: 18px;
        }
        &::before {
          content: "";
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: $gradient-color;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          border-radius: 3px;
          z-index: -1;
          -webkit-transition: $transition;
          transition: $transition;
        }
        &:hover,
        &:focus {
          color: $white-color;
          -webkit-transform: translateY(-5px);
          transform: translateY(-5px);

          &::before {
            opacity: 1;
            visibility: visible;
          }
          svg {
            color: $white-color;
          }
        }
      }
    }
  }
}
.repair-about-image {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 0;
      top: 0;
      z-index: 1;
    }
    &:nth-child(2) {
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 0;
      text-align: center;
      z-index: 2;
      margin: 0 auto;
      border: 5px solid #f8fbff;
      width: 350px;
    }
    &:nth-child(3) {
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    &:nth-child(4) {
      right: 0;
      top: 0;
    }
    &:nth-child(5) {
      left: 0;
      bottom: 0;
    }
  }
}

/*================================================
Repair Services CSS
=================================================*/
.repair-services-area {
  padding-bottom: 50px;
}
.single-repair-services {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 40px 30px;
  text-align: center;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &.bg1 {
    // background-image: url(../images/repair-services-img/1.jpg);
  }
  &.bg2 {
    //    background-image: url(../images/repair-services-img/2.jpg);
  }
  &.bg3 {
    //   background-image: url(../images/repair-services-img/3.jpg);
  }
  &.bg4 {
    //  background-image: url(../images/repair-services-img/4.jpg);
  }
  &.bg5 {
    //  background-image: url(../images/repair-services-img/5.jpg);
  }
  &.bg6 {
    //    background-image: url(../images/repair-services-img/6.jpg);
  }
  &::before {
    position: absolute;
    -webkit-transition: $transition;
    transition: $transition;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white-color;
    z-index: -1;
    border: 1px dashed #cdf1d8;
  }
  &::after {
    position: absolute;
    -webkit-transition: $transition;
    transition: $transition;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    border: 1px dashed #cdf1d8;
  }
  h3 {
    font-size: 18px;
    -webkit-transition: $transition;
    transition: $transition;
    margin: {
      top: 22px;
      bottom: 17px;
    }
  }
  .icon {
    display: inline-block;
    color: $light-green-color;
    width: 85px;
    height: 85px;
    line-height: 85px;
    border: 1px solid #e3f7e9;
    border-radius: 50%;
    -webkit-transition: $transition;
    transition: $transition;

    i::before {
      font-size: 35px;
    }
  }
  p {
    margin-bottom: 25px;
    -webkit-transition: $transition;
    transition: $transition;
  }
  a {
    width: 35px;
    background-color: $bg-color3;
    height: 35px;
    line-height: 35px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 15px;
    }
  }
  &:hover,
  &:focus {
    &::before {
      opacity: 0;
      visibility: hidden;
    }
    &::after {
      opacity: 0.85;
      visibility: visible;
    }
    h3 {
      color: $white-color;
    }
    p {
      color: $white-color;
    }
    .icon {
      border-color: $white-color;
      color: $white-color;
    }
    a {
      background-color: $white-color;
      color: $light-green-color;
    }
  }
}

/*================================================
Repair CTA CSS
=================================================*/
.repair-cta-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: {
    top: 10px;
    bottom: 10px;
  }
}
.cta-repair-content {
  p {
    color: $white-color;
    opacity: 0.95;
    margin-bottom: 25px;
  }
  h3 {
    color: $white-color;
    font-size: 25px;
    margin-bottom: 17px;
  }
}
.cta-repair-img {
  text-align: right;
}
.circle-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.cta-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

/*================================================
Repair Team CSS
=================================================*/
.repair-team-area {
  .container {
    max-width: 1175px;
  }
  .single-team {
    .team-content {
      padding: {
        left: 25px;
        right: 25px;
      }
    }
  }
}

/*================================================
Repair Why Choose Us CSS
=================================================*/
.repair-why-choose-us {
  position: relative;
  z-index: 1;
  background: $gradient-color;

  .section-title {
    h2 {
      color: $white-color;
    }
    .bar {
      &::before {
        background: $white-color;
      }
    }
    p {
      color: $white-color;
      opacity: 0.95;
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 37%;
    width: 100%;
    background: $white-color;
    z-index: -1;
  }
}
.single-repair-box {
  text-align: center;
  background-color: $white-color;
  border: 1px dashed #cdf1d8;
  padding: 30px;
  position: relative;
  z-index: 1;
  -webkit-transition: $transition;
  transition: $transition;

  h3 {
    font-size: 18px;
    margin: {
      top: 22px;
      bottom: 17px;
    }
  }
  p {
    margin-bottom: 25px;
  }
  a {
    width: 40px;
    background-color: #eef0f3;
    height: 40px;
    line-height: 40px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 18px;
    }
  }
  .icon {
    display: inline-block;
    border: 1px solid #6ee9fa;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    position: relative;
    color: $white-color;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      background: $gradient-color2;
      border-radius: 50%;
      margin: 6px;
      -webkit-transition: $transition;
      transition: $transition;
    }
    i::before {
      font-size: 35px;
    }
  }
  .back-icon {
    position: absolute;
    top: 58%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #4457c3;
    opacity: 0.06;
    z-index: -1;

    i::before {
      font-size: 200px;
    }
  }
  &:hover,
  &:focus {
    border-color: $light-green-color;

    a {
      background-color: $light-green-color;
      color: $white-color;
    }
  }
}

/*================================================
Repair Feedback CSS
=================================================*/
.single-repair-feedback {
  background: $white-color;
  border-radius: 5px;
  margin-bottom: 55px;
  position: relative;
  text-align: center;
  padding: {
    left: 40px;
    right: 40px;
    top: 40px;
    bottom: 40px;
  }
  .client-img {
    position: relative;
    text-align: left;
    margin-bottom: 40px;
    display: inline-block;
    padding: {
      left: 108px;
      top: 18px;
    }
    img {
      border-radius: 50%;
      border: 2px solid $light-green-color;
      padding: 3px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      width: 90px;
      height: 90px;
    }
    h3 {
      font-size: 18px;
      margin: 0;
    }
    span {
      display: block;
      color: $purple-color;
      margin-top: 8px;
    }
  }
  p {
    font-size: 15px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 50px;
    height: 50px;
    background: $white-color;
    right: 0;
    margin: 0 auto;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &::after {
    content: "\f11b";
    position: absolute;
    top: 0;
    left: 25px;
    color: #d9dcde;
    font: {
      family: Flaticon;
      size: 30px;
      style: normal;
      size: 65px;
    }
  }
}

/*================================================
IoT Main Banner CSS
=================================================*/
.iot-main-banner {
  position: relative;
  z-index: 1;
  padding: {
    top: 215px;
    bottom: 150px;
  }
}
.iot-banner-content {
  max-width: 445px;

  span {
    color: $light-green-color;
    font-size: 15px;
    display: block;
  }
  h2 {
    font-size: 40px;
    line-height: 1.3;
    margin: {
      top: 7px;
      bottom: 17px;
    }
  }
  .btn {
    margin-top: 10px;
  }
}
.iot-banner-image {
  position: absolute;
  text-align: center;
  right: 30px;
  top: 56%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  img {
    &:first-child {
      max-width: 730px;
    }
    &:last-child {
      position: absolute;
      left: -118px;
      right: 0;
      margin: 0 auto;
      top: 39px;
      z-index: -1;
    }
  }
}
.animate-border {
  span {
    position: absolute;
    display: block;
    width: 5%;
    padding-bottom: 5%;
    top: 51%;
    left: 70%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none;
    border-radius: 50%;
    z-index: -2;

    &:nth-child(1) {
      -webkit-animation: 4.2s ease-out 0.5s infinite normal none running pulsei;
      animation: 4.2s ease-out 0.5s infinite normal none running pulsei;
    }
    &:nth-child(2) {
      -webkit-animation: 4.2s ease-out 1.5s infinite normal none running pulsei;
      animation: 4.2s ease-out 1.5s infinite normal none running pulsei;
    }
    &:nth-child(3) {
      -webkit-animation: 4.2s ease-out 2.5s infinite normal none running pulsei;
      animation: 4.2s ease-out 2.5s infinite normal none running pulsei;
    }
    &:nth-child(4) {
      -webkit-animation: 4.2s ease-out 3.5s infinite normal none running pulsei;
      animation: 4.2s ease-out 3.5s infinite normal none running pulsei;
    }
  }
}
@-webkit-keyframes pulsei {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    border: 1.5px solid #b3eaf8;
    opacity: 1;
    width: 5%;
    padding-bottom: 5%;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0;
    width: 50%;
    border: 1.5px solid #b3eaf8;
    padding-bottom: 50%;
  }
}
@keyframes pulsei {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    border: 1.5px solid #b3eaf8;
    opacity: 1;
    width: 5%;
    padding-bottom: 5%;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0;
    width: 50%;
    border: 1.5px solid #b3eaf8;
    padding-bottom: 50%;
  }
}

/*================================================
IoT Partner CSS
=================================================*/
.iot-partner-area {
  padding: {
    top: 45px;
    bottom: 45px;
  }
}
.single-iot-partner {
  text-align: center;

  a {
    display: block;
    position: relative;

    img {
      width: auto !important;
      display: inline-block !important;

      &:last-child {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        -webkit-transition: $transition;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
      }
    }
    &:hover,
    &:focus {
      img {
        &:last-child {
          opacity: 1;
          visibility: visible;
          top: 0;
        }
      }
    }
  }
}

/*================================================
Iot Services CSS
=================================================*/
.iot-services-area {
  padding-bottom: 50px;
}
.single-iot-services {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 40px 30px;
  text-align: center;
  background-color: $white-color;
  border: 1px dashed #cdf1d8;

  &::after {
    position: absolute;
    -webkit-transition: $transition;
    transition: $transition;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }
  h3 {
    font-size: 18px;
    -webkit-transition: $transition;
    transition: $transition;
    line-height: 1.5;
    margin: {
      top: 22px;
      bottom: 17px;
    }
  }
  .icon {
    display: inline-block;
    color: $light-green-color;
    width: 85px;
    height: 85px;
    line-height: 85px;
    border: 1px solid #e3f7e9;
    border-radius: 50%;
    -webkit-transition: $transition;
    transition: $transition;

    i::before {
      font-size: 35px;
    }
  }
  p {
    margin-bottom: 25px;
    -webkit-transition: $transition;
    transition: $transition;
  }
  a {
    width: 35px;
    background-color: $bg-color3;
    height: 35px;
    line-height: 35px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 15px;
    }
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 1;
      visibility: visible;
    }
    h3 {
      color: $white-color;
    }
    p {
      color: $white-color;
    }
    .icon {
      border-color: $white-color;
      color: $white-color;
    }
    a {
      background-color: $white-color;
      color: $light-green-color;
    }
  }
}

/*================================================
IoT CTA CSS
=================================================*/
.iot-cta-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: {
    top: 10px;
    bottom: 10px;
  }
}
.cta-iot-content {
  h3 {
    color: $white-color;
    font-size: 25px;
    margin-bottom: 17px;
  }
}
.cta-iot-content {
  p {
    color: $white-color;
    opacity: 0.95;
    margin-bottom: 25px;
  }
}
.cta-iot-img {
  text-align: center;
}

/*================================================
IoT Features CSS
=================================================*/
.iot-features-content {
  h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .btn {
    margin-top: 15px;
  }
}
.iot-features-image {
  position: relative;
  text-align: center;
}

/*================================================
IoT Team CSS
=================================================*/
.iot-team-area .container {
  max-width: 1175px;

  .single-team {
    .team-content {
      padding: {
        left: 25px;
        right: 25px;
      }
    }
  }
}

/*================================================
IoT Why Choose Us CSS
=================================================*/
.iot-why-choose-us {
  position: relative;
  z-index: 1;
  background: $gradient-color;

  .section-title {
    h2 {
      color: $white-color;
    }
    .bar {
      &::before {
        background: $white-color;
      }
    }
    p {
      color: $white-color;
      opacity: 0.95;
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 37%;
    width: 100%;
    background: $white-color;
    z-index: -1;
  }
}
.single-iot-box {
  text-align: center;
  background-color: $white-color;
  border: 1px dashed #cdf1d8;
  padding: 30px;
  position: relative;
  z-index: 1;
  -webkit-transition: $transition;
  transition: $transition;

  h3 {
    font-size: 18px;
    margin: {
      top: 22px;
      bottom: 17px;
    }
  }
  p {
    margin-bottom: 25px;
  }
  a {
    width: 40px;
    background-color: #eef0f3;
    height: 40px;
    line-height: 40px;
    color: $black-color;
    border-radius: 50%;

    svg {
      width: 18px;
    }
  }
  .icon {
    display: inline-block;
    border: 1px solid #f3f8fd;
    width: 100px;
    height: 100px;
    line-height: 96px;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      background: $bg-color;
      border-radius: 50%;
      margin: 6px;
      -webkit-transition: $transition;
      transition: $transition;
    }
    i::before {
      font-size: 35px;
    }
    img {
      width: 45px;
    }
  }
  &:hover,
  &:focus {
    border-color: $light-green-color;

    a {
      background-color: $light-green-color;
      color: $white-color;
    }
  }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  padding-top: 80px;
  position: relative;
  z-index: 1;

  .map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: -1;
    bottom: 0;
    opacity: 0.7;
  }
}
.single-footer-widget {
  .logo {
    margin-bottom: 20px;

    a {
      display: inline-block;
    }
  }
  h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.list {
      li {
        margin-bottom: 10px;

        a {
          color: $paragraph-color;

          &:hover {
            color: $light-green-color;
            padding-left: 7px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.footer-contact-info {
      margin-bottom: 20px;

      li {
        position: relative;
        color: $paragraph-color;
        margin-bottom: 8px;
        padding-left: 25px;

        svg {
          position: absolute;
          left: 0;
          width: 15px;
          top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $paragraph-color;

          &:hover {
            color: $light-green-color;
          }
        }
      }
    }
    &.social-links {
      li {
        display: inline-block;
        margin-right: 5px;

        a {
          width: 32px;
          text-align: center;
          line-height: 27px;
          height: 32px;
          border: 1px solid $light-green-color;
          border-radius: 50%;
          color: $light-green-color;

          svg {
            width: 15px;
          }
          &.facebook {
            border-color: #4267b2;
            color: #4267b2;

            &:hover {
              background: #4267b2;
              color: $white-color;
            }
          }
          &.twitter {
            border-color: #38a1f3;
            color: #38a1f3;

            &:hover {
              background: #38a1f3;
              color: $white-color;
            }
          }
          &.linkedin {
            border-color: #0077b5;
            color: #0077b5;

            &:hover {
              background: #0077b5;
              color: $white-color;
            }
          }
          &.instagram {
            border-color: #231f20;
            color: #231f20;

            &:hover {
              background: #231f20;
              color: $white-color;
            }
          }
          &:hover {
            background: $light-green-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.copyright-area {
  margin-top: 80px;
  border-top: 1px solid #d8ebfd;
  text-align: center;
  padding: {
    top: 25px;
    bottom: 25px;
  }
}

/*=============================
Go Top CSS
===============================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 0;
  color: $white-color;
  background-color: $purple-color;
  z-index: 1;
  width: 45px;
  text-align: center;
  height: 45px;
  border-radius: 10px 0 0 10px;
  line-height: 46px;
  -webkit-transition: $transition;
  transition: $transition;

  &:hover {
    color: $white-color;
    background: $light-green-color;
  }
}

/*thankyou-css*/
.thank-you-area {
  text-align: center;
  padding: {
    top: 150px;
    bottom: 150px;
  }
  h1 {
    margin-bottom: 15px;
  }
}

.trips-scrollview {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.logbook-scrollview {
  max-height: 600px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 767px;
$value_two: 768px;
$value_three: 991px;
$value_four: 992px;
$value_five: 1199px;
$value_six: 1550px;

@media only #{$media} and ($feature_max : $value_one) {

    .btn {
        font-size: 13px;
        padding: 13px 33px;
    }
    .ptb-80 {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .section-title {
        margin-bottom: 40px;

        h2 {
            font-size: 20px;
        }
    }
    .owl-theme {
        .owl-dots {
            margin-top: 5px;

            .owl-dot {
                span {
                    width: 12px;
                    height: 12px;
                    margin: 0 3px;
                }
            }
        }
    }

    .navbar-light {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(68, 206, 111, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
        .navbar-toggler {
            border-color: #44ce6f;
            outline: 0 !important;
            border-radius: 0;
        }
    }
    .startp-nav {
        nav {
            .others-option {
                display: none;
            }
            .navbar-nav {
                overflow-y: scroll;
                height: 400px;
                display: block;
                background-color: #fff;
                margin-top: 15px;

                .nav-item {
                    padding: 7px 0;

                    a {
                        margin: {
                            left: 0;
                            right: 0;
                        };
                    }
                    .dropdown_menu {
                        box-shadow: unset;
                        position: relative;
                        top: 0 !important;
                        left: 0;
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                        width: 100%;
                        border-radius: 0;
                        padding: 0 10px;

                        li {
                            border-left: 1px dashed #eeeeee;

                            a {
                                padding: 7px 15px;
                                font-size: 14px;

                                svg {
                                    transform: rotate(90deg);
                                }
                            }
                            .dropdown_menu {
                                position: relative;
                                top: 0 !important;
                                left: 0;
                                opacity: 1 !important;
                                visibility: visible !important;
                            }
                            &:hover {
                                .dropdown_menu {
                                    top: 0 !important;
                                }
                            }
                        }
                    }
                    &:hover {
                        .dropdown_menu {
                            top: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .main-banner {
        height: 100%;
        background-position: center center;
        padding: {
            top: 150px;
            bottom: 80px;
        };
        .hero-content {
            margin-bottom: 45px;

            h1 {
                font-size: 25px;
                line-height: 33px;
                margin-bottom: 13px;
            }
            p {
                margin-bottom: 25px;
            }
        }
        .banner-image {
            text-align: center;

            img {
                display: none;
                position: relative;

                &:last-child {
                    display: block;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
        .banner-form {
            padding: 30px 20px;
            margin: 0 !important;
        }
    }
    
    .hosting-main-banner {
        height: 100%;
        padding: {
            top: 100px;
            bottom: 75px;
        };
        .hosting-banner-content {
            h1 {
                font-size: 25px;
            }
            ul {
                li {
                    font-size: 13px;
                }
            }
            p {
                font-size: 16px;
                margin: {
                    bottom: 25px;
                    top: 20px;
                };
            }
        }
        .hosting-banner-image {
            text-align: center;
            margin-top: 50px;

            img {
                display: none;

                &:nth-child(10) {
                    display: block;
                    position: relative;
                }
            }
        }
    }
    .shape1, .shape2, .shape3, .shape4, .shape5 {
        display: none;
    }

    .boxes-area {
        margin-top: 0;
        padding-bottom: 20px;
    }
    .single-box {
        margin-bottom: 30px;

        h3 {
            font-size: 17px;
        }
    }

    .services-content {
        .box {
            margin-top: 20px;
            padding: 14px 10px;
        }
    }
    .services-right-image {
        text-align: center;
        margin-top: 45px;

        img {
            display: none;
            position: relative;

            &:last-child {
                display: inline-block;
            }
        }
        &.single-right-image {
            img {
                &.bg-image {
                    display: none;
                }
            }
        }
    }
    .services-left-image {
        text-align: center;
        margin-bottom: 45px;

        img {
            display: none;
            position: relative;

            &:last-child {
                display: inline-block;
            }
        }
        &.single-left-image {
            img {
                &.bg-image {
                    display: none;
                }
            }
        }
    }
    .services-area-two {
        padding-bottom: 20px;
    }
    .single-services-box {
        h3 {
            font-size: 17px;
        }
    }

    .services-details-area {
        .separate {
            margin-top: 30px;
        }
    }
    .services-details {
        order: 1;
    }
    .services-details-desc {
        h3 {
            font-size: 18px;
        }
        p {
            font: {
                size: 13px;
            };
        }
    }
    .services-details-image {
        margin-top: 30px;
        order: 2;
    }

    .modal-content {
        .products-content {
            padding: 20px;
        }
        .products-image {
            text-align: right;

            img {
                width: auto;
            }
        }
    }

    .features-area {
        padding-bottom: 20px;
    }
    .single-features {
        padding-left: 20px;

        .icon {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 17px;
            margin-bottom: 10px;
        }
    }

    .team-area {
        padding-bottom: 20px;
    }
    .single-team {
        padding: {
            top: 30px;
            bottom: 30px;
        };
        .team-image {
            padding: {
                left: 20px;
                right: 20px;
            };
        }
        .team-content {
            padding: {
                left: 20px;
                right: 20px;
            };
            .team-info {
                margin: {
                    right: 10px;
                    left: 10px;
                };
                h3 {
                    font-size: 17px;
                }
            }
        }
    }

    .domain-search-content {
        h2 {
            font-size: 20px;
        }
        form {
            border-radius: 0;

            .form-control {
                width: 75%;
            }
            .domain-select {
                width: 25%;
                padding-right: 0;
            }
            button {
                float: unset;
                width: unset;
                height: unset;
                padding: 13px 40px;
            }
        }
        ul {
            display: block;
            margin-top: 15px;

            li {
                display: inline-block;
                -webkit-box-flex: unset;
                -ms-flex: unset;
                flex: unset;
                padding: 0 15px;
                margin-top: 15px;
            }
        }
    }

    .why-choose-us-image {
        margin-bottom: 15px;

        img {
            display: none;

            &:last-child {
                display: block;
            }
        }
    }
    .single-why-choose-us {
        margin-top: 30px !important;

        .icon {
            i::before {
                font-size: 35px;
            }
        }
        h3 {
            font-size: 17px;
        }
    }

    .funfact {
        margin-bottom: 30px;

        h3 {
            font-size: 25px;
            margin-bottom: 3px;
        }
    }
    .contact-cta-box {
        margin: 20px auto 0;
        padding: 30px 20px;

        h3 {
            font-size: 20px;
        }
        .btn {
            position: relative;
            right: 0;
            top: 0;
            margin-top: 15px;
        }
    }

    .works-area {
        padding-bottom: 20px;
    }
    .single-works {
        .icon {
            right: 5px;
            width: 38px;
            height: 38px;
            line-height: 36px;

            svg {
                width: 15px;
            }
        }
        .works-content {
            padding: 15px;

            h3 {
                font-size: 17px;
            }
        }
        &:hover {
            .icon {
                top: 5px;
            }
        }
    }

    .pricing-area {
        padding-bottom: 20px;
    }
    .pricing-table {
        margin-bottom: 30px;
        padding-bottom: 30px;

        .pricing-header {
            h3 {
                font-size: 17px;
            }
        }
        .price {
            margin-bottom: 25px;

            span {
                font-size: 27px;
            }
        }
    }
    .single-pricing-table {
        padding: 30px;
        margin-bottom: 30px;

        .pricing-header {
            h3 {
                font-size: 17px;
            }
            i::before {
                font-size: 40px;
            }
        }
        .price {
            span {
                font-size: 27px;
            }
        }
    }

    .feedback-slides {
        .client-feedback {
            .single-feedback {
                margin-bottom: 30px;
                text-align: center;
                padding: {
                    left: 20px;
                    right: 20px;
                    top: 30px;
                    bottom: 30px;
                };
                .client-img {
                    position: relative;
                    left: 0;
                    top: 0;
                    margin-bottom: 20px;

                    img {
                        display: inline-block;
                    }
                }
                h3 {
                    font-size: 17px;
                }
                p {
                    font-size: 14px;
                }
                &::before {
                    display: none;
                }
            }
        }
        .client-thumbnails {
            margin: 0;

            .next-arrow {
                right: 0;
            }
            .prev-arrow {
                left: 0;
            }
            .next-arrow, .prev-arrow {
                background: rgba(0, 0, 0, 0.55);
                color: #ffffff;
                border: none;
            }
        }
    }
    .testimonials-slides {
        &::before, &::after {
            display: none;
        }
    }
    .single-feedback-item {
        padding: 40px 20px 90px;

        &::before {
            font-size: 30px;
            left: 15px;
        }
    }

    .ready-to-talk {
        padding: {
            top: 50px;
            bottom: 145px;
        };
    }
    .ready-to-talk {
        span {
            display: block;
            margin-top: 25px;
        }
        h3 {
            font-size: 20px;
        }
    }
    .partner-section {
        .partner-inner {
            padding: {
                left: 20px;
                right: 20px;
            };
        }
        h5 {
            font-size: 17px;
            line-height: 23px;
        }
    }

    .blog-area {
        padding-bottom: 20px;
    }
    .single-blog-post {
        .blog-image {
            img {
                width: 100%;
            }
        }
        .blog-post-content {
            h3 {
                font-size: 17px;
            }
        }
    }

    .page-title-area {
        padding: {
            top: 150px;
            bottom: 65px;
        };
        .shape8 {
            top: auto;
            right: auto;
            left: 15px;
            bottom: 20px;
        }
        .shape7 {
            left: 15%;
            top: 29%;
        }
    }

    .about-image {
        margin-bottom: 45px;
    }
    .about-inner-area {
        margin-top: 0px;

        .about-text {
            margin-top: 30px;

            h3 {
                font-size: 17px;
            }
        }
    }

    .project-details-desc {
        h3 {
            font-size: 17px;
            margin-top: 0;
        }
        p {
            font-size: 13px;
        }
        .project-details-information {
            .single-info-box {
                float: left;
                width: 50%;
                margin-top: 20px;

                h4 {
                    font-size: 17px;
                }
            }
        }
    }

    .products-details {
        margin-top: 30px;
    }
    .products-details-tabs {
        #tabs {
            li {
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .content {
            p {
                font-size: 13px;
            }
        }
    }

    .faq-accordion {
        padding: 30px 20px;

        .accordion {
            .accordion-item {
                .accordion-title {
                    padding: 10px;
                    font-size: 15px;
                }
            }
        }
    }
    .faq-contact {
        padding: 30px 20px;

        h3 {
            font-size: 20px;
        }
    }

    .pagination-area {
        margin-top: 25px;
    }

    .blog-details-area {
        .blog-details {
            .article-img {
                .date {
                    width: 80px;
                    height: 80px;
                    font-size: 20px;
                    padding-top: 16px;
                    line-height: 23px;
                }
            }
            .article-content {
                padding: 30px 20px;

                ul {
                    li {
                        margin-bottom: 5px;
                    }
                    &.category {
                        margin-bottom: 20px;
                    }
                }
                h3 {
                    font-size: 17px;
                    line-height: 29px;
                }
                p {
                    margin-top: 16px;
                }
                .blockquote {
                    padding: 18px;

                    p {
                        font-size: 14px;
                    }
                }
                .share-post {
                    ul {
                        li {
                            a {
                                line-height: 35px;
                            }
                        }
                    }
                }
            }
        }
        .post-controls-buttons {
            padding: 30px 20px;
        }
        .post-comments {
            padding: 30px 20px;

            h3 {
                font-size: 17px;
            }
            .single-comment {
                padding-left: 0;

                .comment-img {
                    position: relative;
                    margin-bottom: 25px;
                }
                &.left-m {
                    margin-left: 20px;
                }
            }
        }
        .leave-a-reply {
            padding: 30px 20px;

            h3 {
                font-size: 17px;
            }
        }
    }
    
    .contact-info-area {
        padding-bottom: 20px;
    }
    .contact-info-box {
        padding: 30px 20px;
        margin-bottom: 30px;

        h3 {
            font-size: 17px;
        }
    }
    #contactForm {
        margin-top: 45px;
    }

    .coming-soon-area {
        height: 100%;
        padding: {
            top: 170px;
            bottom: 170px;
        };
        .social-list {
            bottom: 20px;
        }
    }
    .coming-soon-content {
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 14px;
        }
        form {
            .submit-btn {
                position: relative;
                right: 0;
                top: 0;
                height: 45px;
                border-radius: 40px;
                width: 130px;
                font-size: 14px;
                margin-top: 18px;
                -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.3);
                box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.3);
            }
        }
        #timer {
            div {
                width: 100px;
                height: 100px;
                margin: 0 10px;
                padding-top: 18px;
                font-size: 30px;
                margin-bottom: 25px;

                span {
                    font-size: 13px;
                }
            }
        }
    }

    .error-content {
        margin-top: 0;

        .notfound-404 {
            height: 95px;
            margin-bottom: 25px;

            h1 {
                font-size: 75px;
            }
        }
        h3 {
            font-size: 19px;
            margin-bottom: 15px;
        }
        p {
            font-size: 13px;
        }
    }

    .repair-main-banner {
        padding: {
            top: 150px;
            bottom: 85px;
        };
    }
    .repair-banner-content {
        h1 {
            line-height: 1.4;
            margin-bottom: 17px;
            font-size: 25px;
        }
    }
    .repair-banner-image {
        margin-top: 60px;

        img {
            &:nth-child(2) {
                top: 0;
                right: 0;
            }
            &:nth-child(3) {
                top: -35px;
            }
            &:nth-child(4) {
                display: none;
            }
        }
    }

    .repair-about-content {
        margin-bottom: 40px;

        .sub-title {
            font-size: 14px;
        }
        h2 {
            font-size: 20px;
        }
        ul {
            li {
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 15px;
            }
        }
    }
    .repair-about-image {
        text-align: center;
        padding: {
            top: 35px;
            bottom: 35px;
        };
        img {
            &:nth-child(2) {
                width: 250px;
                position: relative;
            }
            &:nth-child(4), &:nth-child(5) {
                display: none;
            }
        }
    }

    .repair-services-area {
        padding-bottom: 20px;
    }
    .single-repair-services {
        padding: 30px 20px;

        h3 {
            font-size: 17px;
        }
        .icon {
            width: 65px;
            height: 65px;
            line-height: 65px;

            i::before {
                font-size: 25px;
            }
        }
    }
    
    .repair-cta-area {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .cta-repair-content {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font-size: 21px;
            margin-bottom: 17px;
        }
        p {
            font-size: 13px;
        }
    }
    .cta-repair-img {
        text-align: center;
    }

    .single-repair-feedback {
        border-radius: 5px;
        margin-bottom: 50px;
        padding: {
            left: 20px;
            right: 20px;
            top: 20px;
            bottom: 20px;
        };
        &::after {
            font-size: 30px;
            left: 10px;
        }
        .client-img {
            margin-bottom: 29px;
            padding: {
                left: 85px;
                top: 13px;
            };
            img {
                width: 70px;
                height: 70px;
            }
            span {
                margin-top: 5px;
            }
            h3 {
                font-size: 17px;
            }
        }
        p {
            font-size: 14px;
        }
    }

    .single-repair-box {
        padding: 20px;

        .icon {
            width: 75px;
            height: 75px;
            line-height: 75px;

            i::before {
                font-size: 25px;
            }
        }
        h3 {
            font-size: 17px;
        }
        .back-icon {
            i::before {
                font-size: 170px;
            }
        }
    }

    .faq-accordion {
        .accordion {
            .accordion__item {
                .accordion__button {
                    font-size: 14px;
                    padding: 10px 15px;
                }
            }
        }
    }

    .iot-main-banner {
        padding: {
            top: 150px;
            bottom: 80px;
        };
    }
    .iot-banner-content {
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 25px;
            margin-bottom: 17px;
            line-height: 1.4;
        }
    }
    .iot-banner-image {
        position: relative;
        right: 0;
        top: 0;
        -webkit-transform: unset;
        transform: unset;
        margin-top: 50px;

        img {
            &:first-child {
                max-width: 100%;
            }
            &:last-child {
                display: none;
            }
        }
    }
    .animate-border {
        span {
            top: 78%;
            left: 41%;
        }
    }

    .iot-services-area {
        padding-bottom: 20px;
    }
    .single-iot-services {
        padding: 30px 20px;

        .icon {
            width: 65px;
            height: 65px;
            line-height: 65px;

            i::before {
                font-size: 25px;
            }
        }
        h3 {
            font-size: 17px;
        }
    }

    .iot-cta-area {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .cta-iot-content {
        text-align: center;
        margin-top: 35px;

        h3 {
            font-size: 20px;
            line-height: 1.5;
        }
    }

    .iot-features-content {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-bottom: 30px;
    }
    .iot-features-content {
        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            line-height: 1.5;
        }
    }
    .iot-features-image {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .single-iot-box {
        padding: 20px;

        .icon {
            width: 85px;
            height: 85px;
            line-height: 85px;

            img {
                width: 40px;
            }
            i::before {
                font-size: 25px;
            }
        }
        h3 {
            font-size: 17px;
            margin: {
                top: 20px;
                bottom: 15px;
            };
        }
    }

    .footer-area {
        padding-top: 50px;
    }
    .single-footer-widget {
        padding: 0 !important;
        margin-bottom: 30px;

        h3 {
            font-size: 17px;
            margin-bottom: 20px;
        }
    }
    .copyright-area {
        margin-top: 20px;
        padding: {
            top: 20px;
            bottom: 20px;
        };
    }
    
}

@media only #{$media} and ($feature_min : $value_two) and ($feature_max : $value_three) {
    
    .startp-nav {
        nav {
            .others-option {
                display: none;
            }
            .navbar-nav {
                .nav-item {
                    .dropdown_menu {
                        left: -30px;

                        li {
                            .dropdown_menu {
                                left: 165px;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &.is-sticky {
            padding: 5px 0;
        }
    }

    .main-banner {
        height: 100%;
        padding: {
            top: 185px;
            bottom: 165px;
        };
        .banner-image {
            text-align: center;
            margin-top: 45px;

            img {
                position: relative;
                display: none;

                &:last-child {
                    display: block;
                }
            }
        }
        .hero-content {
            h1 {
                font-size: 30px;
                margin-bottom: 15px;
            }
        }
        .banner-form {
            padding: 50px;
            margin: 45px 0 !important;
        }
    }
    
    .hosting-main-banner {
        height: 100%;
        padding: {
            top: 120px;
            bottom: 165px;
        };
        .hosting-banner-content {
            h1 {
                font-size: 30px;
            }
            ul {
                li {
                    font-size: 13px;
                }
            }
            p {
                font-size: 16px;
            }
        }
        .hosting-banner-image {
            margin-top: 50px;

            img {
                position: relative;
                display: none;

                &:nth-child(10) {
                    display: block;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                }
            }
        }
    }
    .shape1, .shape2, .shape3, .shape4, .shape5, .shape7 {
        display: none;
    }

    .boxes-area {
        padding-bottom: 50px;
        margin-top: -65px;
    }
    .single-box {
        margin-bottom: 30px;
    }

    .why-choose-us-image {
        margin-bottom: 65px;

        img {
            display: none;

            &:nth-child(3) {
                display: block;
            }
            &:last-child {
                display: block;
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
    }

    .services-right-image {
        text-align: center;
        margin-top: 45px;

        img {
            display: none;
            position: relative;

            &:last-child {
                display: block;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        &.single-right-image {
            img.bg-image {
                display: none;
            }
        }
    }
    .services-left-image {
        text-align: center;
        margin-bottom: 45px;

        img {
            display: none;
            position: relative;

            &:last-child {
                display: block;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        &.single-left-image {
            img.bg-image {
                display: none;
            }
        }
    }

    .single-features {
        padding-left: 20px;

        .icon {
            position: relative;
            left: 0;
            top: 0;
            margin-bottom: 25px;
        }
    }

    .about-image {
        margin-bottom: 45px;
    }
    .about-inner-area {
        margin-top: 30px;

        .about-text {
            margin-top: 30px;
        }
    }
    
    .pricing-area {
        padding-bottom: 50px;
    }
    .pricing-table {
        margin-bottom: 30px;
    }
    .single-pricing-table {
        margin-bottom: 30px;
    }

    .page-title-area {
        padding: {
            top: 130px;
            bottom: 50px;
        };
        .shape8 {
            top: auto;
            right: auto;
            left: 20px;
            bottom: 20px;
        }
    }
    
    .project-details-desc {
        .project-details-information {
            .single-info-box {
                width: 33.3333%;
            }
        }
    }

    .contact-info-area {
        padding-bottom: 50px;
    }
    .contact-info-box {
        margin-bottom: 30px;
    }
    #contactForm {
        margin-top: 45px;
    }

    .coming-soon-content {
        h1 {
            font-size: 35px;
        }
        p {
            font-size: 15px;
        }
        #timer {
            div {
                padding-top: 20px;
                font-size: 30px;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .error-content {
        .notfound-404 {
            height: 205px;

            h1 {
                font-size: 170px;
            }
        }
    }

    .repair-main-banner {
        padding-top: 155px;
    }
    .repair-banner-content {
        text-align: center;
        margin-bottom: 115px;

        h1 {
            margin-bottom: 18px;
            font-size: 30px;
        }
        img {
            &:nth-child(4) {
                left: 0;
                top: -40px;
                margin: 0 auto;
            }
        }
    }
    
    .repair-about-content {
        margin-bottom: 45px;
    }
    .repair-about-image {
        text-align: center;
        padding: 35px 0;

        img {
            &:nth-child(2) {
                position: relative;
            }
            &:nth-child(4), &:nth-child(5) {
                display: none;
            }
        }
    }

    .repair-cta-area {
        padding-top: 80px;
    }
    .cta-repair-content {
        margin-bottom: 30px;
    }

    .repair-why-choose-us {
        &::before {
            height: 40%;
        }
    }

    .iot-main-banner {
        padding: {
            top: 190px;
            bottom: 100px;
        };
    }
    .iot-banner-content {
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 30px;
        }
    }
    .iot-banner-image {
        position: relative;
        right: 0;
        top: 0;
        -webkit-transform: unset;
        transform: unset;
        margin-top: 80px;

        img {
            &:first-child {
                max-width: 100%;
            }
        }
    }
    .animate-border {
        span {
            top: 70%;
            left: 42%;
        }
    }

    .services-details-area {
        .separate {
            margin-top: 40px;
        }
    }
    .services-details {
        order: 1;
    }
    .services-details-image {
        order: 2;
        margin-top: 40px;
    }

    .modal-content {
        .products-image {
            text-align: right;

            img {
                width: auto;
            }
        }
        .products-content {
            padding: 25px;
        }
        button.close {
            z-index: 3;
        }
    }

    .products-details {
        margin-top: 30px;
    }

    .iot-cta-area {
        padding: {
            top: 80px;
            bottom: 80px;
        };
    }
    .cta-iot-content {
        text-align: center;
        margin-top: 40px;
    }

    .iot-features-content {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .iot-features-image {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-top: 40px;
    }

    .iot-why-choose-us {
        &::before {
            height: 40%;
        }
    }

    .single-footer-widget {
        margin-bottom: 30px;
    }
    .copyright-area {
        margin-top: 50px;
    }

}

@media only #{$media} and ($feature_min : $value_four) and ($feature_max : $value_five) {
    
    .main-banner {
        .hero-content {
            h1 {
                font-size: 35px;
            }
        }
        .banner-image {
            img {
                &:nth-child(1) {
                    top: -140px;
                    left: -120px;
                }
                &:nth-child(2) {
                    right: 160px;
                    top: -286px;
                }
                &:nth-child(3) {
                    left: -30px;
                    top: -15px;
                }
                &:nth-child(4) {
                    right: 15%;
                    top: 105px;
                }
                &:nth-child(5) {
                    left: 15%;
                    top: -120px;
                }
                &:nth-child(6) {
                    right: 37%;
                    top: -160px;
                }
                &:nth-child(7) {
                    right: 20px;
                    top: -110px;
                }
                &:nth-child(8) {
                    right: 25px;
                    top: -140px;
                }
                &:nth-child(9) {
                    right: 28%;
                    top: -25px;
                }
                &:nth-child(10) {
                    left: 29%;
                    top: -35px;
                }
                &:nth-child(11) {
                    left: 26%;
                    top: -160px;
                }
                &:nth-child(12) {
                    left: 15px;
                    top: -110px;
                }
                &:nth-child(13) {
                    left: 50%;
                    top: -18px;
                }
                &:nth-child(14) {
                    right: 98px;
                    top: -20px;
                }
            }
        }
    }

    .hosting-main-banner {
        .hosting-banner-content {
            h1 {
                font-size: 35px;
            }
        }
        .hosting-banner-image {
            img {
                &:nth-child(1) {
                    top: -85px;
                }
                &:nth-child(2) {
                    top: 175px;
                    left: 30px;
                }
                &:nth-child(3) {
                    top: 170px;
                    right: 30px;
                }
                &:nth-child(4) {
                    left: 18%;
                    top: -23px;
                }
                &:nth-child(5) {
                    right: 0;
                    top: -115px;
                }
                &:nth-child(6) {
                    left: 2%;
                    top: -173px;
                }
                &:nth-child(7) {
                    top: -244px;
                    left: -73px;
                }
                &:nth-child(8) {
                    left: 11%;
                    top: -200px;
                }
                &:nth-child(9) {
                    top: 40px;
                    left: 0;
                }
            }
        }
    }
    .shape1 {
        display: none;
    }

    .startp-nav {
        nav {
            .navbar-nav {
                .nav-item {
                    a {
                        margin: {
                            left: 10px;
                            right: 10px;
                        };
                    }
                }
            }
        }
    }

    .services-left-image {
        &.single-left-image {
            img {
                &:first-child {
                    top: -30px;
                }
            }
        }
    }
    .services-right-image {
        &.single-right-image {
            img {
                &:first-child {
                    top: -30px;
                }
            }
        }
    }

    .repair-main-banner {
        padding: {
            top: 185px;
            bottom: 110px;
        };
    }
    .repair-banner-image {
        img {
            &:nth-child(4) {
                display: none;
            }
            &:nth-child(3) {
                top: -35px;
            }
            &:nth-child(2) {
                top: -30px;
            }
        }
    }
    
    .repair-team-area {
        .single-team {
            .team-content {
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }

    .iot-main-banner {
        padding: {
            top: 200px;
            bottom: 120px;
        };
    }
    .iot-banner-image {
        img {
            &:first-child {
                max-width: 470px;
            }
            &:last-child {
                top: -10px;
            }
        }
    }

    .iot-team-area {
        .single-team {
            .team-content {
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }

}

@media only #{$media} and ($feature_min : $value_six) {

    .iot-banner-image {
        right: 90px;
        top: 56%;
        transform: translateY(-50%);

        img {
            &:first-child {
                max-width: 90%;
            }
            &:last-child {
                left: -128px;
                top: 50px;
            }
        }
    }
    .animate-border {
        span {
            top: 51%;
            left: 70%;
        }
    }

}